<template>
  <section>
    <div class="row">
      <div class="col-12">
        <HistoryList :whereBy=" whereBy " :key="whereBy" />
      </div>
      
    </div>
  
  </section>
</template>


<script>
  // import * as serviceAPI from '@/services/API.service';
  // import * as serviceMain from '@/services/main.service';
import HistoryList from '@/components/Menu/HistoryList.vue'
  export default {
    name: 'History',
    components: {
      HistoryList
    },
    props: {
      whereBy: {
        type: String,
        required: true
      }
    },
      data: function () {
      return {
       
      }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    },
    methods: {
      
   
    },
    computed: {
      
    },
  }

</script>

<style scoped lang="scss">

</style>