<template>
  <section>
    <button @click="openModal" type="button" class="btn-visible-df-lightgray">แก้ไขข้อมูลลูกค้า</button>
    <Modal ref="EditModalRef" size="40" :buCode="buCode">

      <template v-slot:header>
        <p>แก้ไขข้อมูลลูกค้า</p>
      </template>
      <template v-slot:body>
        <div  class="p-3">
          <div class="py-2">
              <span style="font-family: 'kittithadabold75regular'; font-size:1.3em; ">แก้ไขข้อมูลลูกค้า</span>
              <button :class='`bg-${buCode}`' style="border: none;
              color: white;
              padding: 2px 12px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 16px;
              float:right;
              cursor: pointer;
              border-radius:4px;" @click="saveEditUser()"> <strong style="font-family: 'kittithadamedium65regular';">บันทึก</strong>
              </button>
          </div>
          <hr style="margin:0px;">
          <br>
          <div class="p-0 m-0" >
            <div class="row col-12 p-0 m-0">
              <div class="col-lg-6 col-md-12">
                  <label for="nmetxt"> ชื่อ-นามสกุล </label>
                  <input type="text" class="form-control" name="nmetxtEModak"  placeholder="Name"  maxlength="150" v-model="dataEdit.CNAME" />
              </div>

              <div class="col-lg-6 col-md-12">
                  <label for="mbltxt"> โทรศัพท์ติดต่อ </label>
                  <input type="text" class="form-control" disabled name="mbltxtEModak"  placeholder="Mobile" v-model='dataEdit.MOBILE' maxlength="10" />
              </div>
            </div>
            <div class="row col-lg-12 p-0 m-0">
              <div class="col-lg-6 col-md-12">
                  <label for="emltxt">Email </label>
                  <input type="email" class="form-control" name="emltxtEModak"  maxlength="100" placeholder="E mail" v-model="dataEdit.EMAIL" />
              </div>
              <div class="col-lg-6 col-md-12">
                  <label for="t1ctxt"> หมายเลข The 1</label>
                  <input type="text" class="form-control" name="t1ctxtEModak"  placeholder="The 1" v-model="dataEdit.T1C" maxlength="16" />
              </div>
            </div>
          </div>
        </div>
        <hr style="margin:0px;">
        <div class="p-3">
          <EditHeaderQT showData='1' page="orderhistory" :orderHistoryRef="cartref" :buCode="buCode"/>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import EditHeaderQT from '@/components/CheckOut/EditHeaderQT.vue';
import Modal from '@/components/Modal.vue'
export default {
  name: "HistoryEditUser",
  data() {
    return {
          dataEdit:{
                      CNAME:'',
                      MOBILE:'',
                      EMAIL:'',
                      T1C:'',
                    }
    };
  },
  components: {
    Modal,
    EditHeaderQT
  },
  props: [
    "cartref",
    "stcode",
    "buCode"
  ],
  methods: {
    async openModal(){
      try {
          this.$refs.EditModalRef.showmodal()
          this.startEditUser()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },
    async startEditUser(skc, STCODE){
      //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      // console.log(this.cartref);
      try {
          let data = {
            "REF": this.cartref,
            "STCODE": this.stcode,
          }
          let getAPI = await serviceAPI.call_API('post', `history/getQuotationHeader`, data, 'auth');
          // this.listData = getAPI.data.dbitems;
          this.dataEdit.CNAME = getAPI.data.dbitems[0].custname;
          this.dataEdit.MOBILE = getAPI.data.dbitems[0].mobile;
          this.dataEdit.EMAIL = getAPI.data.dbitems[0].email;
          this.dataEdit.T1C = getAPI.data.dbitems[0].The1;
      } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
      }
    },
    async saveEditUser(){
      // console.log(this.cartref);
      try {
          let data = this.dataEdit;
          await serviceAPI.call_API('post', 'history/confirmEditCust', data,'auth');
          serviceMain.showSuccessAlertWithTime(this, "แก้ไข User สำเร็จ",1500)
          this.$emit('confirmEdit',this.dataEdit)
          this.editForm = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
    }
  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    }
};
</script>

<style scoped>

</style>
