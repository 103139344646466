<template>
  <section  >
    <button @click="openModal"  type="button" :class="`btn-visible-${getBU} mb-1 bg-${getBU}`"  qtsostatus="^sostatus^" qtexpired="^qtexpired^"  >รายละเอียด</button>
  <div>
    <Modal ref="HistoryModalRef" size="80" >
      <template v-slot:header>
        <p>รายละเอียดสินค้า</p>
      </template>
      <template v-slot:body>
        <div :id='`history-detail-${cartref}`'>
          <div class="row px-3 m-0 mt-3">
            <div class="col-12 mb-2">
              <VueBarcode :value="cartref">
                ไม่สามารถสร้าง Barcode ได้
              </VueBarcode>
            </div>
          </div>
          <div class="card">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">เลขที่รายการ</th>
                  <th scope="col">วันที่สั่งซื้อ</th>
                  <th scope="col">สถานะรายการ</th>
                  <th scope="col">จัดส่งโดย</th>
                  <th scope="col">สาขา</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ listData[0][0]["#ref"] ? listData[0][0]["#ref"] : ''}}</th>
                  <td>{{ listData[0][0]["#orderdate"] }}</td>
                  <td>{{ listData[0][0]["#sttname"] }}</td>
                  <td>{{ listData[0][0]["#dlvtype"] }}</td>
                  <td>{{ listData[0][0]["#sorderstore"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="col-12 text-center">
            <div class="track px-5"  style="max-width: 90%;">
              <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">ยืนยันใบเสนอราคา</span> </div>
              <div class="line"></div>
              <div  class="step step2 "> <span class="icon">  </span> <span class="text">รอชำระเงิน</span> </div>
              <div class="line lineca"></div>
              <div  class="step step3 "> <span class="icon">  </span> <span class="text">ชำระเงินแล้ว</span> </div>
              <div class="line lineca"></div>
              <div  class="step step4 "> <span class="icon">  </span> <span  class="text">จัดสินค้า</span> </div>
              <div class="line lineca"></div>
              <div  class="step step5 "> <span class="icon">  </span> <span  class="text">จัดส่งสินค้า</span> </div>
              <div class="line lineca"></div>
              <div  class="step step6 "> <span class="icon">  </span> <span  class="text">รับสินค้าแล้ว</span> </div>
            </div>
          </div> -->
          <div class='col-12'>
                <StatusHistoryvfix v-if="qtSaleSource=='UV'" :status="qtSoStatus" />
                <StatusHistory v-else :status="qtSoStatus" />
          </div>
          <div class="row px-3 m-0 mt-3">
            <div class="col-12 mb-2">
              <p class="h5 ml-3 mt-3">รายละเอียดสินค้า</p>
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">สินค้า</th>
                    <th scope="col"></th>
                    <th scope="col" v-if="qtSaleSource=='UV'">ทุน</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">จำนวน</th>
                    <th scope="col">ยอดรวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(e, index) in listData[1]" :key="index">
                    <td class="p-0">
                      <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKC" :key="e.SKC" v-if="!(e.SKC == '')"/>
                    </td>
                    <td class="py-1">{{ e.PRN }} <br>({{ e.SKC }}) </td>
                    <td class="py-1" v-if="qtSaleSource=='UV'">{{ e.NORCOST }}</td>
                    <td class="py-1">{{ e.UPC }}</td>
                    <td class="py-1">{{ e.QNT }}</td>
                    <td class="py-1">{{ e.AMT }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row px-3 m-0 mt-3">
            <div class="col-12 mb-2">
              <p class="h5 ml-3 mt-3">รายละเอียดการชำระเงิน</p>
              <div class="">
                <div class="row">
                  <div class="col"><p>total</p></div>
                  <div class="col text-end">
                    <p>{{ getSumAMT }} บาท</p>
                    <p></p>
                  </div>
                </div>
              </div>

              <div class=" colorbody">
                <div class="row">
                  <div class="col">
                    <p>discount</p>
                    <p>ส่วนลด MKT null</p>
                  </div>
                  <div class="col text-end">
                    <p>{{ listData[0][0]["#discplpp"] }} บาท</p>
                    <p>{{ listData[0][0]["#discmkt"] }} บาท</p>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="row">
                  <div class="col">Order Total</div>
                  <div class="col text-end">{{ listData[0][0]["#gtotamt"] }} บาท</div>
                </div>
              </div>
              
              <div class="colorbody" v-if="qtSaleSource=='UV'">
                <br>
                <div class="row">
                  <div class="col">
                    <p>Cost (706)</p>
                    <p>TOtal (706)</p>
                    <p>GP</p>
                  </div>
                  <div class="col text-end">
                    <p>{{ listData[0][0]["#cost706"] }} บาท</p>
                    <p>{{ listData[0][0]["#amt706"] }} บาท</p>
                    <p>{{ listData[0][0]["#gp"] }} %</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-12 pm-0'>
            <p class="h5 ml-3 mt-3">ข้อมูลส่วนลด</p>
            <div class='row pm-0' v-if="listData[6].length != 0">
                <div class='col-2' style='font-weight:boler;'>
                    Seq
                </div>
                <div class='col-4' style='font-weight:boler;'>
                    Product
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Quant
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Disc
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Remark
                </div>
            </div>
            <div :type='`${listData[6][0].TY}`' class='row pm-0' style='' v-if="listData[6].length != 0">
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].SEQ }}
                </div>
                <div class='col-4' style='font-weight:boler;'>
                    {{ listData[6][0].NME }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].QNT }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].DSC }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].REM }}
                </div>
            </div>
            <!-- <div class='row pm-0' style=''><div class='col-xs-12' id='noplpp' style='' v-if="listData[6].length == 0">--ไม่พบ PLPP --</div></div> -->
          </div>
          <div class="col-12 p- m-0">
            <div class="row m-0 mt-3 d-flex align-items-start">
              <div class="col p-0 mx-1 card mb-2">
                <div class='col-12 billaddressh'>
                    <span style='margin:0;font-weight:bolder;font-size:1.2em;'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-truck"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                            />
                          </svg>
                          ที่อยู่จัดส่ง
                    </span>
                </div>
                <table class="table table-borderless table-striped container-fluid p-3 m-0">
                  <tbody>
                    <tr v-for="(e, index) in listData[2]" :key="index">
                      <td class="p-0" style="font-size:1rem;">{{ e.key }}</td>
                      <td class="p-0" style="font-size:1rem;">{{ e.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col mx-1 card" v-if="listRows[7] != 0">
                <div class='row pm-0' style='background-color:white;border-radius:4px;'>
                    <div class='col-12 billaddressh'>
                        <span style='margin:0;font-weight:bolder;font-size:1.2em;'>
                            <i class="fas fa-sticky-note"></i> ที่อยู่ใบกำกับภาษี/เลขที่ประจำตัวผู้เสียภาษี
                        </span>
                    </div>
                    <table class="table table-borderless table-striped container-fluid p-3 m-0">
                      <tbody>
                        <tr v-for="(e, index) in listData[7]" :key="index">
                          <td class="p-0" style="font-size:1rem;">{{ e.key }}</td>
                          <td class="p-0" style="font-size:1rem;">{{ e.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
              <div class="col mx-1 card">
                <p style='margin:0;font-weight:bolder;font-size:1.2em;'>ข้อมูลการชำระเงิน</p>
                <p>ไม่พบข้อมูลการชำระเงิน</p>
              </div>
            </div>
            
          </div>
        </div>
        <!-- <button type="button" :class="`btn bt w-100 bg-${getBU}`" data-dismiss="modal" @click="goPrint()">พิมพ์ใบยืนยันการชำระเงิน</button> -->
        <PrintHistoryDetail :cartref="cartref" :listData="listData" :listRows="listRows" :qtSoStatus="qtSoStatus" :qtSaleSource="qtSaleSource" :getSumAMT="getSumAMT"/>
      </template>
    </Modal> 
  </div>

  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
import ImageBySKU from '@/components/ImageBySKU.vue';
import printJS from 'print-js'
import StatusHistoryvfix from '@/components/Menu/StatusHistoryvfix.vue';
import StatusHistory from '@/components/Menu/StatusHistory.vue';
import PrintHistoryDetail from '@/components/Menu/PrintHistoryDetail.vue';

export default {
  name: "HistoryModal",
  data: function() {
    return {
      listData: [[{
                "#ref": "",
                "#orderdate": "",
                "#dscamt": Number,
                "#discplpp": "",
                "#discmkt": "",
                "#gtotamt": Number,
                "#amt706": Number,
                "#cost706": Number,
                "#gpamount": Number,
                "#gp": Number,
                "#custid": "",
                "#custname": "",
                "#mobile": "",
                "#ticket": "",
                "#postime": "",
                "#sstatus": "",
                "#sorderstore": "",
                "#sorderstatus": "",
                "#sttname": "",
                "#dlvcode": "",
                "#dlvtype": "",
                "#stcode": "",
                "#DiscID": Number,
                "#DISCCODE": "",
                "#DiscRate": Number,
                "#DiscType": ""
            }],[{
                "SEQ": Number,
                "PRN": "",
                "SKC": '',
                "QNT": Number,
                "UPC": Number,
                "AMT": Number,
                "SKUSEQ": Number,
                "NORCOST": "",
                "SHOWCOST": "",
                "CAT_CODE": "",
                "PR_VAT": "",
                "COST706": Number,
                "AMT706": Number,
                "GP": Number
            }],[{
                "key": "",
                "value": "",
                "seq": Number
            }],[],[],[{
                "key": "",
                "value": ""
            }],[{
                "SEQ": Number,
                "SKC": Number,
                "NME": "",
                "QNT": Number,
                "AMT": Number,
                "DSC": Number,
                "TY": "",
                "REM": "",
                "SKUSEQ": Number
            }],[{
                "key": "",
                "value": "",
                "seq": Number
            }]],
            listRows:[],
    };
  },
  components: {
    Modal,
    ImageBySKU,
    StatusHistory,
    StatusHistoryvfix,
    PrintHistoryDetail,
  },
  props: [
    "cartref",
    "stcode",
    "qtSoStatus",
    "qtSaleSource",
    
  ],
  methods: {
    async openModal(){
      try {
          this.$refs.HistoryModalRef.showmodal()
          this.startHistory()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },
    async startHistory(){
      //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      let skc = this.cartref;
      let STCODE = this.stcode;
      // console.log(skc+' '+STCODE);
      await this.detailHis(skc, STCODE, 0);
      await this.detailHis(skc, STCODE, 1);
      await this.detailHis(skc, STCODE, 2);
      await this.detailHis(skc, STCODE, 5);
      await this.detailHis(skc, STCODE, 6);
      await this.detailHis(skc, STCODE, 7);
    },
    
    async detailHis(skc, STCODE, mode) {
        try {
            let data = {
              "sku": `${skc}`,
              "STCODE": STCODE,
              "mode": mode
            }
            let getAPI = await serviceAPI.call_API('post', `history/getQuotationDetail`, data, 'auth');
            this.listData[mode] = getAPI.data.dbitems;
            this.listRows[mode] = getAPI.data.dbrows;

        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    goPrint(){
        printJS({
            printable: 'history-detail-'+this.cartref,
            type: 'html',
            //properties: [
            // {field:'name', displayName:'name', columnSize:`50%`},
            // {field:'sex', displayName:'sex',columnSize:`50%`},
            //],
            // header: `<p class="custom-p"> list </p>`,
            // style: '#printCons {width: 600px;} .no-print{width: 0px} .itemText1 { width: 200px } .itemText2 { width: 200px } .itemText3 { width: 200px } .itemText4 { width: 200px }',
            // gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
            // gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
            // repeatTableHeader: true,
            // scanStyles:true,
            targetStyles: ['*'],
            ignoreElements:['no-print','bc','gb']
        })
    }
  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getSumAMT:function(){
        let sum = 0
        for (let i = 0; i < this.listData[1].length; i++) {
          sum = sum + this.listData[1][i].AMT;
        }
        return sum
      }
    }
};
</script>

<style scoped lang="scss">
.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.track {
     position: relative;
     background-color: none;
     height: 1px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 50px;
     margin-top: 40px;
     //margin-left:-40px;
 }

 .line {
     position: relative;
     background-color: #CCC;
     height: 1px;
     width: 40%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 14%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active .icon {
    background: #5cb85c;
    color: #fff;
    padding-top: 5px;
    font-size: 18px;
 }
 
 .track .step.cancel .icon {
     background: #55246c;
     color: #fff
 }

 .track .step.none .icon {
     background: none;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 30px;
     height: 30px;
     line-height: 30px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .icon > .fa {
     font-size: 15px !important;
     margin-top: 8px;
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000;
 }

 .lineca.none{
     background: none;
 }

 .track .text {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
 .track .textr {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
.bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
.print-Div{
  width: 1000px;
}
</style>
