<template>
  <section>
    <!-- <button @click="openModal" :disabled="!canPay" type="button" :class="`btn-visible-${buCode}`" >ยืนยัน PR No.</button> -->
    <button :disabled="sostatus != 'T'" @click="openModal" type="button" :class="`btn-visible-${buCode}`">ตรวจสอบจัดสินค้า</button>

    <Modal ref="ConfirmPickup" size="80" :buCode="buCode">

      <template v-slot:header>
        <p>รายการจัดสินค้า</p>
      </template>
      <template v-slot:body>
        <!-- <div class="container"> -->
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 col-md-12">
            <p class="mr-0 h4 checkout-detail-title" style="float: right;">เลขที่รายการ {{ cartref }} สาขา {{ stcode }}</p>
            <table class="table table-bordered" >
              <thead >
                <tr>
                  <th class="title-little m-0 py-0 text-center">รายการ</th>
                  <th class="title-little m-0 py-0 text-center">รหัสสินค้า</th>
                  <th class="title-little m-0 py-0 text-center">ชื่อสินค้า</th>
                  <th class="title-little m-0 py-0 text-center">ราคา/หน่วย</th>
                  <th class="title-little m-0 py-0 text-center" >จำนวนก่อนจัดสินค้า</th>
                  <th class="title-little m-0 py-0 text-center" >ราคารวมก่อนจัดสินค้า</th>
                  <th class="title-little m-0 py-0 text-center" >จำนวนหลังจัดสินค้า</th>
                  <th class="title-little m-0 py-0 text-center" >ราคารวมหลังจัดสินค้า</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(e, index) in listData" :key="index">
                  <td class="title-little m-0 py-0 text-center">{{ index+1 }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.SKC }}</td>
                  <td class="title-little m-0 py-0">{{ e.PRN }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.UPC }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.QNT }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.AMT }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.PICKUPQNT }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.PICKUPAMT }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row px-3 m-0 mt-3 mb-3">
          <div class="col-4">
            <!-- <button @click="voidQuotationByUser()" type="button" :class="`btn-visible-${buCode}`">ยกเลิกใบเสนอราคา</button> -->
          </div>
          <div class="col-4">
            <!-- <button @click="reSendMail()" type="button" :class="`btn-visible-${buCode}`">ส่งขอApproveอีกครั้ง</button> -->
          </div>
          <div class="col-4">
            <button @click="confirmPickB2B()" type="button" :class="`btn-visible-${buCode}`">ส่งApprove</button>
          </div>
        </div>
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
export default {
  name: "ConfirmPickup",
  data() {
    return {
      listData:[]
    };
  },
  components: {
    Modal
  },
  props: [
    "cartref",
    "stcode",
    "sostatus",
    "buCode"
  ],
  mounted() {
      // this.getOrderDetail()
  },
  methods: {
    async getOrderdetailPickupB2B() {
        try {
            let data = {
                REF:this.cartref,
            }
            let getAPI = await serviceAPI.call_API('post', `directsale/getOrderdetailPickupB2B`, data, 'auth');

            this.listData = getAPI.data.dbitems;

            this.checkStatus()
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },

    async confirmPickB2B() {
        try{
          let _this = this
          this.$swal.fire({
            html: '<h2>ต้องการยืนยันรับสินค้าใช่ หรือไม่?</h2>',
            showCancelButton: true,
            confirmButtonColor: this.getBuColor,
            confirmButtonText: 'ตกลง'
          }).then(async (result) => {
            if (result.value) {
              let data ={
                quotationNO: this.cartref,
                HOSTNAME:`${location.protocol}//${location.host}`
              }
              await serviceAPI.call_API('post', `directsale/confirmPickB2B`, data, 'auth');
              // _this.$emit('conQT')
              // _this.getCurrentBooking()
              serviceMain.showSuccessAlertWithTime(this,`ส่ง Mail ยืนยันใบเนอราคาแล้ว`,3000)
              location.reload(true);
            }
          }).catch((error) => {
              serviceMain.showErrorAlert(this, error)
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error)
        }
    },

    async voidQuotationByUser() {
        try{
          let _this = this
          this.$swal.fire({
            html: '<h2>ต้องการยกเลิกใบเสนอราคา หรือไม่?</h2>',
            showCancelButton: true,
            confirmButtonColor: this.getBuColor,
            confirmButtonText: 'ตกลง'
          }).then(async (result) => {
            if (result.value) {
              let data ={
                REF: this.cartref,
                stc: this.stcode , 
                userid: this.$store.getters["auth/getQTUSERID"]
              }
              await serviceAPI.call_API('post', `directsale/voidQuotationByUser`, data, 'auth');
              serviceMain.showSuccessAlertWithTime(this,`ยกเลิกใบเนอราคาแล้ว`,3000)
              location.reload(true);
            }
          }).catch((error) => {
              serviceMain.showErrorAlert(this, error)
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error)
        }
    },


    priceBaht(para_price) {
      return para_price.toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    async price2Num(para_price) {
      return Number(para_price.replace(/[^0-9.-]+/g,""))
    },

    async checkStatus() {
        if (['', 'C', 'T', 'B', 'U', 'V', 'N'].includes(this.sostatus)) {
            this.canPay=false
        }else{
          this.canPay=true
        }
    },

    async openModal(){
      try {
          // await this.checkStatus()
          // if(!this.canPay){
          //   throw new Error(this.btnCreditName)
          // }
          this.$refs.ConfirmPickup.showmodal()
          this.getOrderdetailPickupB2B()
          // this.startPaylater()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },

  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getQTPLT:function(){
        return this.$store.getters['auth/QTPLT']
      },
      qtShopItemLimit: function() {
        return this.$store.getters["customer/getQTSHOPITEM_LIMIT"];
      },
      canReserve:function(){
        if(this.listData.CUSTIDDS && this.PRNO && this.receiverName && this.licensePlate && !this.reserveNo && !this.reserveDate){
          if(this.creditRemain >= this.listData.AMOUNT){
            return true
          }else{
            this.btnCreditName = "เครดิตไม่พอชำระ"
            return false
          }
        }else{
          return false
        }
      },
      canCancelReserve:function(){
        if(this.listData.CUSTIDDS){
          if(this.reserveNo && this.reserveDate){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },
      amountBaht:function(){
        return this.amount ? this.amount.toLocaleString(undefined, {maximumFractionDigits: 2}) : '0'
      }
    }
};
</script>

<style scoped lang="scss">
.checkout-detail-title {
  padding: 10px 15px;
  font-size: 1.2em;
  font-family: "kittithadabold75regular";
  margin-bottom: 0;
}
.input-paylater{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
  background-color: lightgray !important
}
</style>
