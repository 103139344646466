<template>
  <section>
    <button @click="openModal" :disabled="!listData.CUSTIDDS" type="button" :class="`btn-visible-${buCode}`" >ชำระเครดิต</button>


    <Modal ref="PaylaterModalRef" size="40" :buCode="buCode">

      <template v-slot:header>
        <p>ชำระด้วยเครดิต</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่รายการ {{ listData.REF }} {{ listData.SALESOURCE }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >สาขา {{ listData.STORE }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >ราคารวม Ex.Vat {{ priceBaht(priceBeforeVat) }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >VAT {{ priceBaht(priceVat) }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >ราคารวมสุทธิ {{ amountBaht }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >CustomerID DS {{ listData.CUSTIDDS }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" > creditRemain {{ creditRemain }} บาท</span>
          </div>
          <!-- <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่จองสินค้า {{ listData.reserveMMSNo }}</span>
          </div> -->
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่ชำระเครดิต : {{ reserveNo }} </span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >วันที่ชำระเครดิต : {{ reserveDate }}</span>
          </div>
          <!-- <div class="col-12  p-0 mb-2">
            creditRemain : {{ creditRemain }}
          </div>
          <div class="col-12  p-0 mb-2">
            creditPermanenct : {{ creditPermanenct }}
          </div>
          <div class="col-12  p-0 mb-2">
            creditTemp : {{ creditTemp }}
          </div>
          <div class="col-12  p-0 mb-2">
            creditTotal : {{ creditTotal }}
          </div> -->
          <!-- <div class="col-12  p-0 mb-2">
            <hr class="hr-bold">
          </div>
          <div class="col-12  p-0 mb-2">
            <div class="form-group">
              <label for="mobile">เบอร์</label>
              <input v-model="mobile" class="form-control text-center" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" type = "text" maxlength = "10">
            </div>
          </div>
          <div class="col-12  p-0 mb-2">
            <div class="form-group">
              <label for="sms">ข้อความ SMS ถึงลูกค้า</label>
              <textarea v-model="smsTxt" class="form-control" rows="3" readonly></textarea>
            </div>
          </div> -->
          <div class="col-12 p-0 mb-2" >
            <div class="row">
              <div class="col-12">
                <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >PR No : </span>
                <input class="w-50 font-kitti-bold mr-0 h6 checkout-detail-title" maxlength="50" placeholder="" name="Prno" v-model="PRNO" :disabled="!reserveNo == ''">
              </div>
            </div>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="reserveCreditDS()" :class="`btn-visible-${buCode} ${canReserve ? '':'disabled'}`" :disabled="!canReserve">{{btnCreditName}}</button>
          </div>
          <!-- <div class="col-12  p-0 mb-2">
            <button @click="checkReserveCreditDS()" :class="`btn-visible-${buCode}`" :disabled="!canCancelReserve">เช็คเครดิต</button>
          </div> -->
          <!-- <div class="col-12  p-0 mb-2">
            <button @click="cancelReserveCreditDS()" :class="`btn-visible-${buCode}`" :disabled="!canCancelReserve">ยกเลิกเครดิต</button>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="reserveStockMMS()" :class="`btn-visible-${buCode}`" >testreserve</button>
          </div> -->
        
        </div>
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import * as constant from '@/services/constant';
import QrcodeVue from 'qrcode.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: "ReserveCredit",
  data() {
    return {
      linkPaylater: '',
      mobile:'',
      smsTxt:'',
      bu:'',
      cname:'',
      custid:'',
      btnCreditName:'ชำระเครดิต',
      canPay:false,
      listData:[],
      reserveNo:'',
      reserveDate:'',
      creditRemain:'',
      creditPermanenct: '',
      creditTemp: '',
      creditTotal: '',
      PRNO: '',
      listItems:[],
      resStockMMSStatus: false,
      BATCHID: '',
      listdataMMSRES: [],
      listDataAMT: [],
      priceVat: 0,
      priceBeforeVat:0,
      waitclick: false
    };
  },
  components: {
    QrcodeVue,
    Modal
  },
  props: [
    "cartref",
    "sostatus",
    "sorderref",
    "stcode",
    "amount",
    "qtexpired",
    "buCode",
    "qtapproved"
  ],
  mounted() {
      // this.getOrderDetail()
      this.getDetail4ReserveCredit()
  },
  methods: {
    async getDetail4ReserveCredit() {
        try {
            let data = {
                REF:this.cartref,
            }
            let getAPI = await serviceAPI.call_API('post', `wholesale/getDetail4ReserveCredit`, data, 'auth');

            let getAPI2 = await serviceAPI.call_API('post', `history/getPriceAndRemarkByVfix`, { "ref": this.cartref }, 'auth');
            this.listDataAMT = getAPI2.data.dbitems[0];

            // console.log(getAPI);
            this.listData = getAPI.data.dbitems[0];
            this.reserveNo = getAPI.data.dbitems[0].reserveNo || ''
            this.reserveDate = getAPI.data.dbitems[0].reserveDate || ''
            this.PRNO = getAPI.data.dbitems[0].PRNO || getAPI.data.dbitems[0].PONUM
            this.listItems = JSON.parse(getAPI.data.dbitems[0].JSNITEMS)

            let totallist = this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0
            let priceamount = this.listDataAMT.amount ? await this.price2Num(this.listDataAMT.amount) : 0
            let amt = parseFloat(totallist);
            let beforeVat = amt/1.07
            let vat = amt-beforeVat
            this.priceVat = vat
            this.priceBeforeVat = priceamount - vat

            this.checkStatus()
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },

    priceBaht(para_price) {
      return para_price.toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    async price2Num(para_price) {
      return Number(para_price.replace(/[^0-9.-]+/g,""))
    },

    async checkCreditDS(){
      try {
        let data={
          REF:this.cartref,
          STCODE:this.listData.STORE,
          CustomerDSID:this.listData.CUSTIDDS.trim()
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkCreditDS`,data,'auth');
        // console.log(JSON.parse(getAPI.data.data));
        let getData = JSON.parse(getAPI.data.data);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        this.creditRemain = getData.data.creditremain
        this.creditPermanenct = getData.data.creditpermanenct
        this.creditTemp = getData.data.credittemp
        this.creditTotal = getData.data.credittotal
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkStatus() {
        if (['P', 'Q', 'R', 'O', 'D'].includes(this.sostatus)) {
            this.btnCreditName = "ชำระเครดิตแล้ว"
            this.canPay=false
        } else if (['V', 'U'].includes(this.sostatus)) {
            this.btnCreditName = "ยกเลิกแล้ว"
            this.canPay=false
        } else if (['N'].includes(this.sostatus)) {
            this.btnCreditName = "ไม่อนุมัติ"
            this.canPay=false
        }else{
          // let nowDate = new Date();
          // let expireDate = new Date(this.qtexpired ? this.qtexpired.replace(' ', 'T'): null);
          // if(expireDate < nowDate){
          //     this.btnCreditrName = "หมดอายุ"
          //     this.canPay=false
          // } else{
          //     this.canPay=true
          // }
          this.canPay=true
        }
    },

    async openModal(){
      try {
          // await this.checkStatus()
          // if(!this.canPay){
          //   throw new Error(this.btnCreditName)
          // }
          this.$refs.PaylaterModalRef.showmodal()
          this.checkCreditDS()
          // this.startPaylater()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },

    async reserveCreditDS(){
      try {
        this.waitclick = true
        if(!this.PRNO) {
          serviceMain.showWarningAlert(this,'กรุณาระบุค่า PR No')
          return;
        }

        await serviceAPI.call_API('post',`wholesale/checkPOREFWholesale`,{REF: this.cartref,prNo: this.PRNO},'auth');

        await serviceAPI.call_API('post',`wholesale/updateWSORDER`,{REF: this.sorderref},'auth');

        let data={
          REF:this.cartref,
          SALESOURCE:this.listData.SALESOURCE,
          STCODE:this.listData.STORE,
          AMOUNT:this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0,
          CustomerDSID:this.listData.CUSTIDDS.trim()
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/reserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }

        this.reserveNo = getData.reserveNo
        this.reserveDate = getData.reserveDate
        this.creditRemain = getData.creditRemain

        // await this.reserveStockMMS()
        // if(this.resStockMMSStatus != true){
        //   this.cancelReserveCreditDS()
        // }

        let JSNCREDIT = {
          docNo: this.listData.WSREF.trim(),
          wholeSaleCustomerId: this.listData.CUSTIDWS.trim(),
          directSaleCustomerId:this.listData.CUSTIDDS.trim(),
          directSaleMMSStoreCode: this.listData.STORE,
          reserveNo: getData.reserveNo,
          reserveDate: getData.reserveDate,
          prNo: this.PRNO,
          // reserveMMSNo: this.listdataMMSRES.HOLDREF
          reserveMMSNo: this.listData.reserveMMSNo || ''
        }
        let JSNPOSREF = [getData.reserveNo]
        let datapaid={
          REF:this.cartref ,
          JSNCREDIT:JSNCREDIT,
          JSNPOSREF:JSNPOSREF
        }

        //'{"tendor":"WCRD","order_id":"2210056155","ReserveCredit":"xxxxxx","amount":"000013870000","transaction_datetime":"151122161829"}'
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear().toString();
        let hours = today.getHours().toString(); // => 9
        let min = today.getMinutes().toString(); // =>  30
        let sec = today.getSeconds().toString(); // =>  30
        hours = hours.length > 1 ? hours : '0'+hours;
        min = min.length > 1 ? min : '0'+min;
        sec = sec.length > 1 ? sec : '0'+sec;
        let transaction_datetime = dd+mm+yyyy.substring(2)+hours+min+sec
        let JSNTENDOR = {
          tendor: "WCRD",
          order_id: this.sorderref.trim(),
          ReserveCredit: getData.reserveNo,
          amount:this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0,
          transaction_datetime: transaction_datetime,
        }
        let dataticket={
          REF:this.cartref ,
          JSNTENDOR:JSNTENDOR,
          AMT:this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0
        }
        let getAPIticket = await serviceAPI.call_API('post',`wholesale/savePaymentCredit`,dataticket,'auth');
        let getticket = getAPIticket.data.dbitems;
        console.log(getticket);

        // console.log(datapaid);
        let getAPIpaid = await serviceAPI.call_API('post',`wholesale/paidByCredit`,datapaid,'auth');
        let getsorder = getAPIpaid.data.dbitems;

        // console.log(getsorder);
        serviceMain.showSuccessAlertWithTime(this,`การจองเครดิตสำเร็จ ${getData.reserveNo}`,3000)
        this.$emit("setreloadcard");
        this.checkCreditDS()
      } catch (error) {
        this.waitclick = false
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkReserveCreditDS(){
      try {
        let data={
          RESERVENO:this.reserveNo,
          RESERVEDATE:this.reserveDate,
          STCODE:this.listData.STORE
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkReserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        serviceMain.showSuccessAlertWithTime(this,`การจองเครดิต ${getData.reserveNo} มูลค่า ${getData.amount} สถานะ ${getData.reserveStatus}`,3000)
        this.reserveNo = getData.reserveNo
        this.reserveDate = getData.reserveDate
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async cancelReserveCreditDS(){
      try {
        let data={
          RESERVENO:this.reserveNo,
          RESERVEDATE:this.reserveDate,
          STCODE:this.listData.STORE
        }
        let getAPIcheck = await serviceAPI.call_API('post',`wholesale/checkReserveCreditDS`,data,'auth');
        let getDataCheck = JSON.parse(getAPIcheck.data.data);
        // console.log(getDataCheck);
        if(getDataCheck.status == 0){
          throw new Error(getDataCheck.message)
        }
        if(getDataCheck.reserveStatus != 'Y'){
          throw new Error(`${this.reserveNo} สถานะยกเลิก`)
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/cancelReserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        // serviceMain.showSuccessAlertWithTime(this,`ยกการการจองเครดิต ${getData.reserveNo} สำเร็จ`,3000)
        this.reserveNo = ''
        this.reserveDate = ''
        this.creditRemain = ''
        this.$emit("setreloadcard");
        this.checkCreditDS()

      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async reserveStockMMS(){
      try {
        if(!this.reserveNo){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error reserveNo)')
        }
        // let dataSTORE={
        //   store: this.listData.STORE
        // }
        // let getAPISTORE = await serviceAPI.call_API('get',`service/getSTORE`,dataSTORE,'auth');
        // let store = getAPISTORE.dbitems[0].STORE;
        

        let data={
          REF: this.cartref
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/getBatchID`,data,'auth');
        this.BATCHID = getAPI.data.dbitems[0].BATCH_ID;
        if(!this.BATCHID){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error BATCHID)')
        }

        await this.deleteORDRESERVETEMP()

        for (let i = 0; i < this.listItems.length; i++) {
          let dataINSORS={
            REF: this.cartref, 
            BATCHID: this.BATCHID, 
            STCODE: this.listData.STORE, 
            UPC: this.listItems[i].PCD, 
            QTY: this.listItems[i].QNT, 
            RETL: this.listItems[i].AMT, 
            ORDERNO: this.reserveNo, 
            SEQ: this.listItems[i].SEQ,
            SALESOURCE: this.listData.SALESOURCE
          }
          let getAPIINSORS = await serviceAPI.call_API('post',`wholesale/insertORDRESERVE`,dataINSORS,'auth');
        }

        let dataINSIN={
          BATCHID: this.BATCHID,
        }
        let getAPIINSIN = await serviceAPI.call_API('post',`wholesale/intfORDRESERVEDIRECTSALES`,dataINSIN,'auth');
        console.log(getAPIINSIN);
        this.listdataMMSRES = getAPIINSIN.data.dbitems[0]
        if(getAPIINSIN.data.dbcode > 0){
          this.resStockMMSStatus =  false
          throw new Error(getAPIINSIN.data.dbmessage);
        }
        if(this.listdataMMSRES.RESULT != 'OKOK'){
          this.resStockMMSStatus =  false
          throw new Error(this.listdataMMSRES.RESULT);
        }

        this.resStockMMSStatus = true
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
        this.resStockMMSStatus =  false
      }
    },

    async deleteORDRESERVETEMP(){
      try {
        let data={
          BATCHID: this.BATCHID,
        }
        await serviceAPI.call_API('post',`wholesale/deleteORDRESERVE`,data,'auth');
        // console.log(getData);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getQTPLT:function(){
        return this.$store.getters['auth/QTPLT']
      },
      qtShopItemLimit: function() {
        return this.$store.getters["customer/getQTSHOPITEM_LIMIT"];
      },
      canReserve:function(){
        if(this.waitclick){
          return false
        }
        if(this.listData.CUSTIDDS && this.PRNO && !this.reserveNo && !this.reserveDate){
          if(this.creditRemain >= this.listData.AMOUNT){
            this.btnCreditName = "ชำระเครดิต"
            return true
          }else{
            this.btnCreditName = "เครดิตไม่พอชำระ"
            return false
          }
        }else{
          return false
        }
      },
      canCancelReserve:function(){
        if(this.listData.CUSTIDDS){
          if(this.reserveNo && this.reserveDate){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },
      amountBaht:function(){
        return this.amount ? this.amount.toLocaleString(undefined, {maximumFractionDigits: 2}) : '0'
      },
    }
};
</script>

<style scoped lang="scss">
.input-paylater{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
  background-color: lightgray !important
}
</style>
