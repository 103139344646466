<template>
    <section>
        <div class="shadowDiv" style='overflow:hidden;'>
            <div class='row m-0' style='width: 100%;'>
                <div class="col-12 p-0 m-0">
                    <div class="row m-0">
                        <div class='col px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p>
                            <p class="mb-4"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                        </div>
                        <div class='col px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                        </div>
                        <div class='col px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                        </div>
                        <div class='col px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                        </div>
                        <div class='col px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row m-0' style='width: 100%;'>
                <div class="col-12 p-0 m-0">
                    <div class="row m-0 d-flex align-items-center">
                        <div class='col-3 px-2 m-0' >
                            <p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                            <p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p>
                        </div>
                        <div class='col-9 px-2 m-0' >
                            <p><span class="skeleton-box w-100" style="height: 50px"></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row m-0' style='width: 100%;'>
                <div class="col-12 p-0 m-0">
                    <div class="row m-0">
                        <div class='col-2 px-2 m-0' >
                            <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                        </div>
                        <div class='col-2 px-2 m-0' >
                            <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                        </div>
                        <div class='col-2 px-2 m-0' >
                            <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                        </div>
                        <div class='col-6 px-2 m-0' >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SlotBookingSkeleton'
}
</script>

<style scoped>
    .shadowDiv {
        margin: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 7px;
        height: auto;
    }
    .shadowDiv:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
</style>