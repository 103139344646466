<template>
  <section>
    <button @click="openModal"  type="button" :class="`btn-visible-${buCode}`"  
      :disabled="!canPay || getQTPLT == 0"  >{{btnPaylaterName}}</button>


    <Modal ref="PaylaterModalRef" size="40" :buCode="buCode">

      <template v-slot:header>
        <p>Paylater</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 mb-2">
            <qrcode-vue v-if="!(linkPaylater=='')" :value="linkPaylater" size="200" level="H" />
          </div>
          <div class="col-12  p-0 mb-2">
            <input :id="`paylink-${cartref}`" class="input-paylater w-100" :value="linkPaylater" type="text" disabled>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="copyToClipboard" :class="`btn-visible-black`">คัดลอก</button>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="confirmPaylater" :class="`btn-visible-${buCode}`">เปิดใช้งานลิ้ง Paylater</button>
          </div>
          <div class="col-12  p-0 mb-2">
            <hr class="hr-bold">
          </div>
          <div class="col-12  p-0 mb-2">
            <div class="form-group">
              <label for="mobile">เบอร์</label>
              <input v-model="mobile" class="form-control text-center" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" type = "text" maxlength = "10">
            </div>
          </div>
          <div class="col-12  p-0 mb-2">
            <div class="form-group">
              <label for="sms">ข้อความ SMS ถึงลูกค้า</label>
              <textarea v-model="smsTxt" class="form-control" rows="3" readonly></textarea>
            </div>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="sendSMS" :class="`btn-visible-${buCode}`">ส่ง SMS ถึงลูกค้า</button>
          </div>
        
        </div>
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import * as constant from '@/services/constant';
import QrcodeVue from 'qrcode.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: "PaylaterModal",
  data() {
    return {
      linkPaylater: '',
      mobile:'',
      smsTxt:'',
      bu:'',
      cname:'',
      custid:'',
      btnPaylaterName:'Paylater',
      canPay:false,
      storeUnusePaylater: ['021'],
    };
  },
  components: {
    QrcodeVue,
    Modal
  },
  props: [
    "cartref",
    "sostatus",
    "sorderref",
    "stcode",
    "amount",
    "qtexpired",
    "buCode",
    "qtapproved",
    "allowPayLater"
  ],
  mounted() {
      this.checkStatus()
  },
  methods: {
    async checkStatus() {
        if (['P', 'Q', 'R', 'O', 'D'].includes(this.sostatus)) {
            this.btnPaylaterName = "ชำระเงินแล้ว"
            this.canPay=false
        } else if (['V', 'U'].includes(this.sostatus)) {
            this.btnPaylaterName = "ยกเลิกแล้ว"
            this.canPay=false
        } else if (['N'].includes(this.sostatus)) {
            this.btnPaylaterName = "ไม่อนุมัติ"
            this.canPay=false
        }else{
          let nowDate = new Date();
          let expireDate = new Date(this.qtexpired ? this.qtexpired.replace(' ', 'T'): null);
          if(expireDate < nowDate){
              this.btnPaylaterName = "หมดอายุ"
              this.canPay=false
          } else{
              this.canPay=true
          }
        }
        if(this.storeUnusePaylater.includes(this.stcode)){
          this.btnPaylaterName = "สาขายกเว้น"
          this.canPay=false
        }
        if(this.allowPayLater == '0'){
          this.btnPaylaterName = "ไม่เปิดใช้งาน"
          this.canPay=false
        }
    },
    async openModal(){
      try {
          await this.checkStatus()
          if(!this.canPay){
            throw new Error(this.btnPaylaterName)
          }
          this.$refs.PaylaterModalRef.showmodal()
          this.startPaylater()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },
    async startPaylater(){
      try {
        await this.checkStatus()
        if(!this.canPay){
          throw new Error(this.btnPaylaterName)
        }
        // let d = new Date();
        let d = new Date(this.qtapproved.split('.')[0]);
        let getAPIBU = await serviceAPI.call_API('post','service/findBU',{"STCODE":this.stcode},'auth');
        this.bu = getAPIBU.data.dbitems[0].BU;
        var epar =`ref=${this.sorderref}&time=${d.toLocaleString("en-EN")}&stccode=${this.stcode}&bu=${this.bu}`; //4/4/2563 10:42:02
        //var epar =`ref=${ref}&time=${d.toLocaleString()}&stccode=${stccode}&bu=${getBU}`; //4/4/2563 10:42:02
        //  var port = (location.port) ? `:${location.port}`:'';
        //    var lnk = window.location.protocol+"//"+window.location.hostname+port+"/paymentlater?order="+btoa(epar);
        // this.linkPaylater = constant.paylaterUrl+"?order="+btoa(epar);
        this.linkPaylater = constant.paylaterUrl+"/"+btoa(unescape(encodeURIComponent(epar)));
        let data={
            REF:this.cartref,
            STCODE:this.stcode
        }
        let getAPI = await serviceAPI.call_API('post',`history/getQuotationHeader`,data,'auth');
        let getData = getAPI.data.dbitems[0];
        this.mobile = getData.mobile;
        this.cname = getData.custname;
        this.custid = getData.custid;
        this.setSMS()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async setSMS(){
      try {
        let getBUName ='';
        switch(this.bu) {
          case 'PWB':
            getBUName='พาวเวอร์บาย'
            break;
          case 'bnb':
            getBUName='บ้านแอนด์บียอนด์'
            break;
          case 'at1':
            getBUName='Auto1'
            break;
          default:
            getBUName='พาวเวอร์บาย'
        }
        this.smsTxt = `คลิกลิ้ง \n${this.linkPaylater}\nเพื่อชำระค่าสินค้ากำหนดระยะเวลาภายใน 30 นาที \nขอบคุณที่เลือกใช้บริการ ${getBUName}`
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async copyToClipboard() {
        await this.checkStatus()
        if(!this.canPay){
          throw new Error(this.btnPaylaterName)
        }
        var from = document.getElementById(`paylink-${this.cartref}`);
        var range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(from);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        serviceMain.showSuccessAlertWithTime(this, 'คัดลอกสำเร็จ',1200)
      },
    async confirmPaylater(){
      try {
        await this.checkStatus()
        if(!this.canPay){
          throw new Error(this.btnPaylaterName)
        }
        let d = new Date();
        let getJSONPAY={
          tender:"LATE",
          custref:this.sorderref,
          customer:this.cname ,
          user:this.$store.getters['auth/getQTUSERID'],
          amount:this.amount,
          stc:this.stcode,
          link: this.linkPaylater,
          ondate:d.toLocaleString() 
        }
        let data={
            SORDERREF:this.sorderref,
            JSONPAY:JSON.stringify(getJSONPAY),
            TENDOR:'LATE',
            AMOUNT:this.amount
        }
        
        await serviceAPI.call_API('post',`history/savepaylater`,data,'auth');
        this.$emit("setreloadcard");
        this.$refs.PaylaterModalRef.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async sendSMS(){
      try {
        await this.checkStatus()
        if(!this.canPay){
          throw new Error(this.btnPaylaterName)
        }
        let datasms={
          MOBILE:this.mobile , 
          SMSTXT:this.smsTxt
        }
        let getAPI_SMS =await serviceAPI.call_API('post',`history/smspaylater`,datasms,'auth');

        let data={
          SORDERREF:this.sorderref,
          MOBILE:this.mobile
        }
        
        let getAPI = await serviceAPI.call_API('post',`history/sendSMSForCheckout`,data,'auth');
        serviceMain.showSuccessAlertWithTime(this, 'ส่งสำเร็จ',1200)
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    }
  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getQTPLT:function(){
        return this.$store.getters['auth/QTPLT']
      },
    }
};
</script>

<style scoped lang="scss">
.input-paylater{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
}
</style>
