<template>
    <section>
        <div class='row m-1' style='background-color: white;border-radius: 7px;margin-bottom:.3em;'>
            <div class='col-12 pm-0 m-1'>
                <div class='row pm-0'>
                    <div class='col-12 col-md-3'>
                        <span style='margin:0;font-weight:bolder; font-size:17px;'>
                            เลขที่รายการ {{cardData.REF}} {{(cardData.salesource)}}<br v-show="!$store.getters['auth/getISTB']"><span v-show="!$store.getters['auth/getISTB']">( {{cardData.SORDERREF}} )</span><br>Ticket No. {{cardData.jsnposref}} <br>
                        </span>
                        <span style='margin:0;font-weight:bolder; font-size:17px;' v-if="cardData.OTPreq != 0 || cardData.OTPrqnum">
                            เลขที่OTP {{cardData.OTPrqnum}} <br>
                        </span>
                        <span style='font-weight:lighter;'>{{qtapprovedDate || cardData.orderdate}}</span>
                        <p v-show="!$store.getters['auth/getISTB']" style='font-weight:lighter;'>คัดลอกจาก {{cardData.COPYFROM}}</p>
                    </div>
                    <div class='col-12 col-md-9'>
                        <div class='row pm-0'>
                            <div class='col-4 col-md-3 text-sm-center'>
                                <span style='margin:0;font-weight:bolder;'>สถานะรายการ<br></span>
                                <span style='margin:0;font-weight:lighter;'>( {{cardData.sostatus}} )
                                    {{cardData.sostatusname}}</span>
                            </div>
                            <div class='col-4 col-md-3 text-sm-center'>
                                <span style='margin:0;font-weight:bolder;'>จำนวนสินค้า<br></span>
                                <span style='margin:0;font-weight:lighter;'>{{cardData.nitem}} รายการ</span>
                            </div>
                            <div class='col-4 col-md-3 text-sm-center'>
                                <span style='margin:0;font-weight:bolder;'>ยอดรวม<br></span><span>{{cardData.amount}}
                                    บาท</span>
                            </div>
                            <div class='col-md-3 d-none d-sm-block'>
                                <!-- <HistoryDetail :cartref="cardData.REF" :stcode="cardData.stcode" :qtSoStatus="cardData.sostatus" :qtSaleSource="getQTSALESOURCE" /> -->
                                <button @click="$router.push({ name: 'HistoryDetail', params: { whereBy: whereByData, cartRef: cardData.REF, qtSoStatus: cardData.sostatus, qtSaleSource: cardData.salesource, buCode: cardData.buCode, stcode:cardData.stcode }})"  type="button" :class="`btn-visible-${cardData.buCode} mb-1 bg-${cardData.buCode}`" >รายละเอียด</button>
                                <button v-if="!$store.getters['auth/getISTB']" v-show="(cardData.Paylater == 0 && checkStatus) || cardData.OTPreq == 'true' || cardData.OTPreq == '1'" :disabled="!getQTOTP || !cardData.WSOTP || cardData.OTPappv == null || cardData.OTPreq == null" @click="goOTPREQ" type="button" :class="`mb-1 ${cardData.OTPappv == '0' || cardData.OTPappv == 'false' ?  (cardData.OTPreq == '0' || cardData.OTPreq == 'false' ? 'btn-v-red' : 'btn-v-yellow') : 'btn-v-green'}`">{{cardData.OTPappv == '0' || cardData.OTPappv == 'false' || cardData.OTPappv == null ?  (cardData.OTPreq == '0' || cardData.OTPreq == 'false' || cardData.OTPreq == null ? 'OTP Request' : 'OTP Requested') : 'OTP Approved'}}</button>
                                <button v-if="!$store.getters['auth/getWSFLAG'] && !$store.getters['auth/getISTB']" @click="copyQT(cardData)" type="button"
                                    :class="`btn-visible-${cardData.buCode} mb-1`">คัดลอก</button>
                                <button v-if="canCopySalesource && !$store.getters['auth/getISTB']" @click="copyQTSS(cardData)" type="button" :class="`btn-visible-${cardData.buCode}`">คัดลอก{{cardData.salesource}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='col-12 col-md-3 d-none d-sm-block'>
                <div class='row pm-0' style='padding-bottom: 0px;'>
                    <div v-show="!$store.getters['auth/getISTB']" class='col-12 text-left pm-0'>
                        <span style='margin:0;font-weight:bolder;'>ชื่อลูกค้า </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.custname}}</span> 
                        <br>
                        <span style='margin:0;font-weight:bolder;'>เบอร์โทรศัพท์ </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.mobile}}</span> 
                        <br>
                        <span style='margin:0;font-weight:bolder;'>ชื่อผู้สร้าง </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.userid}} ({{cardData.empname}})</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvShow}} </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> สาขา {{cardData.stsname}}</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvText}}  </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> {{cardData.dlvdate}}</span>
                        <br>
                        <span style='margin:0;font-weight:bolder;'>ส่ง Email/SMS ไปที่ </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.sendEtaxTo}}</span>
                    </div>
                    <div v-show="$store.getters['auth/getISTB']" class='col-12 text-left pm-0'>
                        <span style='margin:0;font-weight:bolder;'>ชื่อผู้สร้าง </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.empname}}</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvShow}} </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> สาขา {{cardData.stsname}}</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvText}}  </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> {{cardData.dlvdate}}</span>
                    </div>
                </div>
            </div>
            <div class='col-12 col-md-9'>
                <StatusHistoryvfix v-if="cardData.salesource=='UV'" :status="cardData.sostatus" />
                <StatusHistoryB2B v-else-if="$store.getters['auth/getISTB']" :status="cardData.sostatus"/>
                <StatusHistory v-else :status="cardData.sostatus"/>
                
            </div>
            <div class='col-6 col-md-3 d-block d-sm-none mt-2'>
                <div class='row pm-0' style='padding-bottom: 0px;'>
                    <div v-show="!$store.getters['auth/getISTB']" class='col-12 col-md-12 pm-0'>
                        <span style='margin:0;font-weight:bolder;'>ชื่อลูกค้า </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.custname}}</span> 
                        <br>
                        <span style='margin:0;font-weight:bolder;'>เบอร์โทรศัพท์ </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.mobile}}</span> 
                        <br>
                        <span style='margin:0;font-weight:bolder;'>ชื่อผู้สร้าง </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.userid}} ({{cardData.empname}})</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvShow}} </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> สาขา {{cardData.stsname}}</span>
                        <br v-if="dlvStatus">
                        <span v-if="dlvStatus" style='margin:0;font-weight:bolder;'>{{dlvText}}  </span>
                        <span v-if="dlvStatus" style="margin:0;font-weight:lighter;"> {{cardData.dlvdate}}</span>
                        <br>
                        <span style='margin:0;font-weight:bolder;'>ส่ง Email/SMS ไปที่ </span>
                        <span style="margin:0;font-weight:lighter;">{{cardData.sendEtaxTo}}</span>
                    </div>

                </div>
            </div>
            <div class='d-block d-sm-none col-6 mt-2'>
                <!-- <HistoryDetail :cartref="cardData.REF" :stcode="cardData.stcode" :qtSoStatus="cardData.sostatus" :qtSaleSource="getQTSALESOURCE" /> -->
                <button @click="$router.push({ name: 'HistoryDetail', params: { whereBy: whereByData, cartRef: cardData.REF, qtSoStatus: cardData.sostatus, qtSaleSource: cardData.salesource, buCode: cardData.buCode }})"  type="button" :class="`btn-visible-${cardData.buCode} mb-1 bg-${cardData.buCode}`" >รายละเอียด</button>
                <button v-if="!$store.getters['auth/getISTB']" v-show="(cardData.Paylater == 0 && checkStatus) || cardData.OTPreq == 'true' || cardData.OTPreq == '1'" :disabled="!getQTOTP || !cardData.WSOTP || cardData.OTPappv == null || cardData.OTPreq == null" @click="goOTPREQ" type="button" :class="`mb-1 ${cardData.OTPappv == '0' || cardData.OTPappv == 'false' ?  (cardData.OTPreq == '0' || cardData.OTPreq == 'false' ? 'btn-v-red' : 'btn-v-yellow') : 'btn-v-green'}`">{{cardData.OTPappv == '0' || cardData.OTPappv == 'false' || cardData.OTPappv == null ?  (cardData.OTPreq == '0' || cardData.OTPreq == 'false' || cardData.OTPreq == null ? 'OTP Request' : 'OTP Requested') : 'OTP Approved'}}</button> 
                <button v-if="!$store.getters['auth/getISTB'] && !$store.getters['auth/getWSFLAG']" @click="copyQT(cardData)" type="button" :class="`mb-1 btn-visible-${cardData.buCode}`">คัดลอก</button>
                <button v-if="canCopySalesource && !$store.getters['auth/getISTB']" @click="copyQTSS(cardData)" type="button" :class="`mb-1 btn-visible-${cardData.buCode}`">คัดลอก{{cardData.salesource}}</button>
            </div>

            <div class='col-12 '>
                <div class='row p-0 mb-2' style='padding-bottom: 1em;'>
                    <div class='col-12 p-0' v-if="userCon">
                        <div class="row m-0">
                            <div v-if="!$store.getters['auth/getISTB']" class='col-6 col-sm-6 col-md-3' v-show="(cardData.OTPreq == 0 || cardData.OTPappv == 'true' || cardData.OTPappv == '1' || cardData.OTPappv == null || !checkStatus)">
                                <PaylaterModal class="mb-1" :cartref="cardData.REF"  :sostatus="cardData.sostatus" :sorderref="cardData.SORDERREF"
                                    :stcode="cardData.stcode" :amount="cardData.amount" :qtexpired="cardData.qtexpired" :qtapproved="cardData.qtapproved" :buCode="cardData.buCode" @setreloadcard="setreloadcard"/>
                            </div>
                            <div class='col-6 col-sm-6 col-md-3' v-if="$store.getters['auth/getISTB']" >
                                <ConfirmPRNo class="mb-1" :cartref="cardData.REF"  :sostatus="cardData.sostatus" :sorderref="cardData.SORDERREF"
                                    :stcode="cardData.stcode" :amount="cardData.amount" :qtexpired="cardData.qtexpired" :qtapproved="cardData.qtapproved" :buCode="cardData.buCode" @setreloadcard="setreloadcard"/>
                            </div>
                            <div class='col-6 col-sm-6 col-md-3' v-if="$store.getters['auth/getISTB']">
                                <ConfirmPickup class="mb-1" :cartref="cardData.REF"  :sostatus="cardData.sostatus" :stcode="cardData.stcode" :buCode="cardData.buCode"/>
                                <!-- <button :disabled="cardData.sostatus != 'T'" @click="confirmPickB2B(cardData.REF)" type="button" :class="`btn-visible-${cardData.buCode}`">ส่งApprove</button> -->
                            </div>
                            <div class='col-6 col-sm-6 col-md-3' v-if="$store.getters['auth/getISTB']">
                                <button :disabled="cardData.sostatus != 'B'" @click="reSendMail(cardData.REF)" type="button" :class="`btn-visible-${cardData.buCode}`">ส่งขอApproveอีกครั้ง</button>
                            </div>
                            <div class='col-6 col-sm-6 col-md-3' v-show="cardData.OTPreq == 0 || cardData.OTPappv == 'true' || cardData.OTPappv == '1' || cardData.OTPappv == null || !checkStatus" >
                                <PrintQuotation :cartref="cardData.REF" :stcode="cardData.stcode" :dlvcode="cardData.dlvcode" :buCode="cardData.buCode" :SkuList="cardData.SkuList" :sorderref="cardData.SORDERREF"/>
                            </div>
                            <div v-if="!$store.getters['auth/getISTB']" class='col-6 col-sm-6 col-md-3'>
                                <HistoryEditUser :cartref="cardData.REF" :stcode="cardData.stcode" :buCode="cardData.buCode"/>
                            </div>
                            <!-- <div class='col-12 col-md-3' v-if="canVoid && expDate">
                                <button @click="voidBooking(cardData.REF)" type="button" :class="`btn-visible-${getBU}`">Void Booking</button>
                            </div> -->
                            <div class='col-6 col-sm-6 col-md-3' v-if="canVoidVFix && expDate && checkStatus && !$store.getters['auth/getISTB']">
                                <button @click="csvoidAllBooking(cardData.REF)" type="button" :class="`btn-visible-${cardData.buCode}`">Void Booking</button>
                            </div>
                            <!-- <div class='col' v-if="getWSJOB">
                                <button @click="sendUpdateJobWholeSale()" type="button" :class="`btn-visible-${cardData.buCode}`">Update WS</button>
                            </div> -->
                            <div class='col-6 col-sm-6 col-md-3' v-if="getWSJOB" >
                                <ReserveCredit class="mb-1" :cartref="cardData.REF"  :sostatus="cardData.sostatus" :sorderref="cardData.SORDERREF"
                                    :stcode="cardData.stcode" :amount="cardData.amount" :qtexpired="cardData.qtexpired" :qtapproved="cardData.qtapproved" :buCode="cardData.buCode" @setreloadcard="setreloadcard"/>
                            </div>
                            <div class='col-6 col-sm-6 col-md-3' v-if="cardData.sostatus == 'C' && !$store.getters['auth/getISTB']">
                                <button @click="voidQuotation(cardData.REF,cardData.stcode,cardData.userid)" type="button" :class="`btn-visible-${cardData.buCode}`">ยกเลิกใบเสนอราคา</button>
                            </div>
                            <!-- <div class='col-6 col-sm-6 col-md-3' >
                                <button @click="voidReserveStockMMS(cardData.REF,cardData.stcode,cardData.COPYFROM)" type="button" :class="`btn-visible-${cardData.buCode}`">test</button>
                            </div> -->
                            <!-- <div class='col-12 col-md-3' v-if="cardData.OTPflag"> -->
                            <!-- <div class='col-12 col-md-3' v-if="isOTP"> -->
                                <!-- <button @click="goOTPREQ" type="button" :class="`btn-visible-${getBU}`">OTP Request</button> -->
                                <!-- <button :disabled="!canOTP" @click="goOTPREQ" type="button" :class="`btn-visible-${getBU}`">OTP Request</button> -->
                            <!-- </div> -->
                        </div>
                    </div>

                    <div class='col-12 p-0' v-else-if="adminCon">
                        <div class="row m-0">
                            <div class='col-12 col-md-4  '>
                                <button @click="confirmQT(cardData.REF,cardData.stcode,cardData.userid,cardData.amount)"
                                    type="button" :class="`btn-visible-${cardData.buCode} mb-1`">อนุมัติ</button>
                            </div>
                            <div class='col-12 col-md-4  '>
                                <button
                                    @click="cancleQT(cardData.REF,cardData.stcode,cardData.userid,remarknonapprove,cardData.amount)"
                                    type="button" :class="`btn-visible-${cardData.buCode} mb-1`"
                                    style="background-color:black;">ไม่อนุมัติ</button>
                            </div>
                            <div class='col-12 col-md-4  '>
                                <textarea maxlength="500" v-model="remarknonapprove" style="width:100%;"
                                    placeholder="ไม่อนุมัติเพราะ" name="remarknonapprove" rows="1"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class='col-12 p-0' v-else>
                        <div class="row m-0">
                            <div class='col-12 col-md-6  '>
                                <button @click="voidQT(cardData.REF,cardData.stcode,cardData.userid)" type="button"
                                    :class="`btn-visible-${cardData.buCode} mb-1`"
                                    style="background-color:black;">ยกเลิกรายการขออนุมัติ</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import * as serviceAPI from '@/services/API.service';
    import * as serviceMain from '@/services/main.service';
    import HistoryDetail from '@/components/Menu/HistoryDetail.vue'
    import HistoryEditUser from '@/components/Menu/HistoryEditUser.vue'
    import PaylaterModal from '@/components/Menu/PaylaterModal.vue'
    import StatusHistory from '@/components/Menu/StatusHistory.vue'
    import PrintQuotation from '@/components/Menu/PrintQuotation.vue'
    import StatusHistoryvfix from '@/components/Menu/StatusHistoryvfix.vue'
    import StatusHistoryB2B from '@/components/Menu/StatusHistoryB2B.vue'
    import ConfirmPRNo from '@/components/Menu/ConfirmPRNo.vue'
    import ReserveCredit from '@/components/Menu/ReserveCredit.vue'
    import ConfirmPickup from '@/components/Menu/ConfirmPickup.vue'


    export default {
        name: 'HistoryCard',
        components: {
            HistoryDetail,
            HistoryEditUser,
            PaylaterModal,
            StatusHistory,
            PrintQuotation,
            StatusHistoryvfix,
            StatusHistoryB2B,
            ConfirmPRNo,
            ReserveCredit,
            ConfirmPickup
        },
        props: [
            "cardData",
            "whereByData"
        ],
        data: function () {
            return {
                adminCon: false,
                userCon: false,
                dlvShow: "",
                listData: [],
                remarknonapprove: '',
                dlvStatus: true,
                dlvText:'',
                CurrentBooking:'',
                canVoidVFix:false,
                bookingIDItm:[],
                listReseveStock: [],
                listdataMMSRES: []
            }
        },
        mounted() {
            this.checkBU()
            this.checkCon()
            this.getCurrentBooking()
        },
        methods: {

            async copyQT(e) {
                try {
                    if(this.getQTCUSTINFO.MOBILE == '') {
                        this.$swal.fire({
                            html: 'ยังไม่ได้เลือกลูกค้า ต้องการไปหน้าค้นหาลูกค้าหรือไม่?',
                            showCancelButton: true,
                            // confirmButtonColor: this.getBuColor,
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'ไปค้นหาลูกค้า',
                            denyButtonText: "อยู่หน้าเดิม",
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                this.$router.push({ name: 'FindUser'})
                            }
                        }).catch((error) => {
                            serviceMain.showErrorAlert(this, error)
                        });
                        // this.$router.push({ name: 'FindUser'})
                        // throw new Error('กรุณาเลือกลูกค้าก่อนครับ')
                    }else{
                        let data = {
                            MCID: this.$store.getters['customer/getQTMCID'],
                            MOBILE: this.getQTCUSTINFO.MOBILE,
                            EMAIL: this.getQTCUSTINFO.EMAIL,
                            REF: e.REF,
                            STCODE: this.StoreCode
                        }
                        let getAPI = await serviceAPI.call_API('post', `history/CopyQuotation`, data, 'auth');
                        var getCUSTREF = getAPI.data.dbitems[getAPI.data.dbrows - 1].CUSTREF;
                        var getSHOPITEM = getAPI.data.dbitems[getAPI.data.dbrows - 1].SUMQNT;
                        var getSTCODE = getAPI.data.dbitems[getAPI.data.dbrows - 1].COPYFROMSTCODE;
                        let getSTNAME = getAPI.data.dbitems[getAPI.data.dbrows - 1].STNAME;
                        let getBU = getAPI.data.dbitems[getAPI.data.dbrows - 1].BU;
                        // let getAPIBU = await serviceAPI.call_API('post', 'service/findBU', {
                        //     "STCODE": getSTCODE
                        // }, 'auth');
                        // let getBU = getAPIBU.data.dbitems[0].BU;

                        this.$store.dispatch('customer/setQTSHOPITEM', getSHOPITEM);

                        // if (!this.StoreCode) {
                            this.$store.dispatch('service/setQTDFSTORE', getSTCODE)
                            this.$store.dispatch('service/setQTSTORENAME', getSTNAME)
                        // }

                        this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                        this.$store.dispatch('customer/setQTBU', getBU);
                        if(getCUSTREF){
                            let data={
                            REF: getCUSTREF, 
                            SALESOURCE:this.getQTSUBSALESOURCE
                            }
                            await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
                        }
                        this.$router.push({
                            name: 'Cart'
                        });
                    }
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },

            async copyQTSS(e) {
                try {
                    if(this.getQTCUSTINFO.MOBILE == '') {
                        throw new Error('กรุณาเลือกลูกค้าก่อนครับ')
                    }
                    let data = {
                        MCID: this.$store.getters['customer/getQTMCID'],
                        MOBILE: this.getQTCUSTINFO.MOBILE,
                        EMAIL: this.getQTCUSTINFO.EMAIL,
                        REF: e.REF,
                        STCODE: this.StoreCode
                    }
                    let getAPI = await serviceAPI.call_API('post', `history/copyQuotationSalesource`, data, 'auth');
                    console.log(getAPI.data);
                    var getCUSTREF = getAPI.data.dbitems[getAPI.data.dbrows - 1].CUSTREF;
                    var getSHOPITEM = getAPI.data.dbitems[getAPI.data.dbrows - 1].SUMQNT;
                    var getSTCODE = getAPI.data.dbitems[getAPI.data.dbrows - 1].COPYFROMSTCODE;
                    let getSTNAME = getAPI.data.dbitems[getAPI.data.dbrows - 1].STNAME;
                    let getBU = getAPI.data.dbitems[getAPI.data.dbrows - 1].BU;
                    let getSaleSource = getAPI.data.dbitems[getAPI.data.dbrows - 1].SALESOURCE
                    // let getAPIBU = await serviceAPI.call_API('post', 'service/findBU', {
                    //     "STCODE": getSTCODE
                    // }, 'auth');
                    // let getBU = getAPIBU.data.dbitems[0].BU;

                    this.$store.dispatch('customer/setQTSHOPITEM', getSHOPITEM);

                    // if (!this.StoreCode) {
                        this.$store.dispatch('service/setQTDFSTORE', getSTCODE)
                        this.$store.dispatch('service/setQTSTORENAME', getSTNAME)
                    // }

                    this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                    this.$store.dispatch('customer/setQTCOPYREF', "")
                    this.$store.dispatch('customer/setQTCREDITBAL', "")
                    this.$store.dispatch('customer/setQTBU', getBU);
                    this.$store.dispatch('auth/setQTSUBSALESOURCE', getSaleSource)
                    this.$router.push({
                        name: 'Cart'
                    });
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },

            async checkBU() {
                let dlvCode = this.cardData.dlvcode
                let BUcode = this.cardData.bu
                if (dlvCode == "D") {
                    this.dlvText = "วันที่ส่งสินค้า "
                    if(BUcode=="PWB"){
                        this.dlvShow = 'จัดส่งโดยไทวัสดุ'
                    }else if(BUcode=="BNB"){
                        this.dlvShow = 'จัดส่งโดยบ้านแอนด์บียอนด์'
                    }else if(BUcode=="AT1"){
                        this.dlvShow = 'จัดส่งโดย Auto1'
                    }
                } else if (dlvCode == "P") {
                    this.dlvText = "วันที่รับสินค้า "
                    if(BUcode=="PWB"){
                        this.dlvShow = 'รับที่ไทวัสดุ'
                    }else if(BUcode=="BNB"){
                        this.dlvShow = 'รับที่บ้านแอนด์บียอนด์'
                    }else if(BUcode=="AT1"){
                        this.dlvShow = 'รับที่ Auto1'
                    }
                } else {
                    this.dlvShow == "";
                    this.dlvStatus = false
                }
            },
            async confirmQT(REF, stcode, userid, amt) {
                try {
                    let data = {
                        ref: REF,
                        stc: stcode,
                        userid: userid
                    }
                    let getAPI = await serviceAPI.call_API('post', `history/confirmQT`, data, 'auth');
                    let getsorder = getAPI.data.dbitems[0];
                    let getsorderref = getsorder.SORDERREF;
                    alert(`สร้างใบเสนอราคาหมายเลข (${getsorderref}) เรียบร้อยแล้ว`);
                    this.sendNotify('approve', REF, amt, stcode)
                    this.$emit('conQT')

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async cancleQT(REF, stcode, userid, remarknonapprove, amt) {
                try {
                    let data = {
                        ref: REF,
                        stc: stcode,
                        userid: userid,
                        remarknonapprove: remarknonapprove
                    }
                    await serviceAPI.call_API('post', `history/cancleQT`, data, 'auth');
                    this.sendNotify('cancel',REF,amt,stcode,this.remarknonapprove)
                    this.$emit('conQT')

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async voidQT(REF, stcode, userid) {
                try {
                    let data = {
                        ref: REF,
                        stc: stcode,
                        userid: userid
                    }
                    await serviceAPI.call_API('post', `history/voidQT`, data, 'auth');

                    this.$emit('conQT')

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async sendNotify(para_status, para_ref, para_total, para_stc, para_remark) {
                try {
                    let data = {
                        ref: para_ref
                    }
                    let getAPI = await serviceAPI.call_API('post', `service/ApproveResponseTo`, data, 'auth');
                    let getDetail = getAPI.data.dbitems[0];
                    let getResponseToLine = getDetail.ResponseToLine;
                    let getResponseToEmail = getDetail.ResponseToEmail;
                    let getCREATEUSER = getDetail.CREATEUSER.trim();
                    let getpara_remark = para_remark ? para_remark : '';

                    this.mailNotify(para_status, getResponseToEmail, '', para_total, para_ref, para_stc, getCREATEUSER,getpara_remark);
                    this.lineNotify(para_status,getResponseToLine,para_total,para_ref,para_stc,getCREATEUSER,getpara_remark);

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },

            async mailNotify(para_status, para_mailto, para_mailcc, para_total, para_ref, para_stc, para_name,para_remark) {
                try {
                    let data = {
                        QT: para_ref,
                        MAIL_TO: para_mailto,
                        MAIL_CC: para_mailcc,
                        TOTAL: para_total,
                        FN: para_status,
                        STC: para_stc,
                        USERNAME: para_name,
                        REMARK: para_remark,
                        HOSTNAME:`${location.protocol}//${location.host}`
                    };
                    await serviceAPI.call_API('post', `service/mailNotify`, data, 'auth');
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async lineNotify(para_status, para_token, para_total, para_ref, para_stc, para_name, para_remark) {
                try {
                    let data = {
                        QT: para_ref,
                        TOKEN: para_token,
                        TOTAL: para_total,
                        FN: para_status,
                        STC: para_stc,
                        USERNAME: para_name,
                        REMARK: para_remark,
                        HOSTNAME:`${location.protocol}//${location.host}`
                    };
                    await serviceAPI.call_API('post', `service/lineNotify`, data, 'auth');
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }

            },
            async checkCon(){
                let profile = this.getQTPROFILE;
                let admin = profile.split("X")
                let status = this.cardData.sostatus

                if(status == 'B' && !this.$store.getters['auth/getISTB']){
                    for(let i=1; i<admin.length; i++){
                        if(admin[i] == '0076'|| admin[i] == '0090'){
                                this.adminCon = true;
                        }
                    }
                } else{
                    this.userCon = true;
                }
            },
            async voidBooking(ref){
                try{
                    let _this = this
                this.$swal.fire({
                    html: '<h2>ต้องการยกเลิกการจองรอบรถ หรือไม่?</h2>',
                    showCancelButton: true,
                    confirmButtonColor: this.getBuColor,
                    confirmButtonText: 'ตกลง'
                }).then(async (result) => {
                    if (result.value) {
                        
                        let data ={
                            ref: ref,
                            salesource: _this.getQTSALESOURCE
                        }
                        await serviceAPI.call_API('post', `history/voidBooking`, data, 'auth');
                        await serviceAPI.call_API('post', `history/voidAllBooking`, data, 'auth');
                        _this.$emit('conQT')
                    }
                }).catch((error) => {
                    serviceMain.showErrorAlert(this, error)
                });
                } catch (error) {
                    serviceMain.showErrorAlert(this, error)
                }
            },
            async getCurrentBooking() {
                try {
                    let data = {
                        ref: this.cardData.REF
                    }
                    let getAPI = await serviceAPI.call_API('post', `history/getCurrentBookingV2`, data, 'auth');
                    let getsorder = getAPI.data.dbitems[0];
                    this.CurrentBooking = getsorder
                    this.canVoidVFix = true
                    let bookingID = JSON.parse(this.CurrentBooking.Booking_id)
                    let bookingIDItms = []
                    for (let i = 0; i < bookingID.length; i++) {
                        if(!bookingIDItms.includes(bookingID[i].Booking_id)){
                            bookingIDItms.push(bookingID[i].Booking_id)
                        }
                    }
                    // console.log(bookingIDItms);
                    this.bookingIDItm = bookingIDItms
                } catch (error) {
                    this.canVoidVFix = false
                    // serviceMain.showErrorAlert(this, error.message)
                }
            },
            async confirmPickB2B(REF) {
                try{
                    let _this = this
                    this.$swal.fire({
                        html: '<h2>ต้องการยืนยันรับสินค้าใช่ หรือไม่?</h2>',
                        showCancelButton: true,
                        confirmButtonColor: this.getBuColor,
                        confirmButtonText: 'ตกลง'
                    }).then(async (result) => {
                            if (result.value) {
                                let data ={
                                    quotationNO: REF,
                                    HOSTNAME:`${location.protocol}//${location.host}`
                                }
                                await serviceAPI.call_API('post', `directsale/confirmPickB2B`, data, 'auth');
                                // _this.$emit('conQT')
                                // _this.getCurrentBooking()
                                serviceMain.showSuccessAlertWithTime(this,`ส่ง Mail ยืนยันใบเนอราคาแล้ว`,3000)
                                location.reload(true);
                            }
                    }).catch((error) => {
                            serviceMain.showErrorAlert(this, error)
                    });
                } catch (error) {
                    serviceMain.showErrorAlert(this, error)
                }
            },
            async csvoidAllBooking(REF) {
                try{
                    let _this = this
                    this.$swal.fire({
                        html: '<h2>ต้องการยกเลิกการจองรอบรถ หรือไม่?</h2>',
                        showCancelButton: true,
                        confirmButtonColor: this.getBuColor,
                        confirmButtonText: 'ตกลง'
                    }).then(async (result) => {
                            if (result.value) {
                                
                                let data ={
                                    ref: REF
                                }
                                for (let i = 0; i < this.bookingIDItm.length; i++) {
                                    console.log(this.bookingIDItm[i]);
                                    let dataV ={
                                        ref: REF,
                                        bookingid: this.bookingIDItm[i],
                                        salesource: _this.cardData.salesource
                                    }
                                    await serviceAPI.call_API('post', `history/voidBookingVFix`, dataV, 'auth');
                                }
                                await serviceAPI.call_API('post', `history/csvoidAllBooking`, data, 'auth');
                                _this.$emit('conQT')
                                _this.getCurrentBooking()
                                serviceMain.showSuccessAlertWithTime(this,`ยกเลิกรอบจองรถเรียบร้อยแล้ว`,3000)
                            }
                    }).catch((error) => {
                            serviceMain.showErrorAlert(this, error)
                    });
                } catch (error) {
                    serviceMain.showErrorAlert(this, error)
                }
            },
            async voidQuotation(REF, stcode, userid, COPYFROM) {
                try{
                    let _this = this
                    this.$swal.fire({
                        html: `<h2>ต้องการยกเลิกใบเสนอราคาเลขที่ ${REF} หรือไม่?</h2>`,
                        showCancelButton: true,
                        confirmButtonColor: this.getBuColor,
                        confirmButtonText: 'ตกลง'
                    }).then(async (result) => {
                            if (result.value) {
                                
                                let data = {
                                    ref: REF,
                                    stc: stcode,
                                    userid: userid
                                }
                                if(this.getWSJOB) {
                                    await this.voidReserveStockMMS(REF,stcode,COPYFROM)
                                }
                                await serviceAPI.call_API('post', `history/voidQuotation`, data, 'auth');
                                _this.$emit('conQT')
                                _this.getCurrentBooking()
                                serviceMain.showSuccessAlertWithTime(this,`ยกเลิกใบเสนอราคาเลขที่ ${REF} เรียบร้อยครับ`,3000)
                            }
                    }).catch((error) => {
                            serviceMain.showErrorAlert(this, error)
                    });
                } catch (error) {
                    serviceMain.showErrorAlert(this, error)
                }
            },
            async voidReserveStockMMS(REF,stcode,COPYFROM){
                try {
                    let dataSTORE={
                        store: stcode
                    }
                    let getAPISTORE = await serviceAPI.call_API('post',`service/getSTORE`,dataSTORE,'auth');
                    // console.log(getAPISTORE);
                    let store = getAPISTORE.data.dbitems[0].STORE;
                    if(!store){
                        throw new Error('ไม่สามารถยกเลิกใบเสนอราคาได้ (error Store)')
                    }

                    let data={
                        REF: REF
                    }
                    let getAPI = await serviceAPI.call_API('post',`wholesale/getBatchID`,data,'auth');
                    let BATCHID = getAPI.data.dbitems[0].BATCH_ID;
                    if(!BATCHID){
                        throw new Error('ไม่สามารถยกเลิกใบเสนอราคาได้ (error BATCHID)')
                    }
                    
                    let getAPIRSVNUM = await serviceAPI.call_API('post', `wholesale/selectJsnCredit`, data, 'auth');
                    let RSVNUM = getAPIRSVNUM.data.dbitems[0].JsnCredit ? JSON.parse(getAPIRSVNUM.data.dbitems[0].JsnCredit).reserveMMSNo : '';
                    if(!RSVNUM){
                        throw new Error('ไม่สามารถยกเลิกใบเสนอราคาได้ (error RSVNUM)')
                    }

                    let datadetail = {
                        "ref": REF
                    }
                    let getAPI1 = await serviceAPI.call_API('post', `history/orderdetail`, datadetail, 'auth');
                    let dataOrder = getAPI1.data.dbitems;

                    // await this.deleteORDRESERVETEMP()

                    this.listReseveStock = []
                    let tempsku =  dataOrder.map(rl => rl.SKC)
                    tempsku = [...new Set(tempsku)]
                    for (let i = 0; i < dataOrder.length; i++) {
                        for (let j = 0; j < tempsku.length; j++) {
                            if(tempsku[j] == dataOrder[i].SKC && tempsku[j] != '154338'){
                                let k = (this.listReseveStock.findIndex(itm => itm.SKC == tempsku[j]))
                                if (k > -1) {
                                    this.listReseveStock[k].QNT = this.listReseveStock[k].QNT + dataOrder[i].QNT
                                }else{
                                    this.listReseveStock.push(dataOrder[i])
                                }
                            }
                        }
                    }

                    for (let i = 0; i < this.listReseveStock.length; i++) {
                        let dataINSORSV={
                            REF: REF,
                            BATCHID: BATCHID,
                            STCODE: store, 
                            UPC: this.listReseveStock[i].PCD, 
                            QTY: this.listReseveStock[i].QNT, 
                            // RETL: this.listReseveStock[i].AMT, 
                            ORDERNO: COPYFROM, 
                            SEQ: this.listReseveStock[i].SKUSEQ,
                            // SALESOURCE: this.getQTSALESOURCE
                            RSVNUM: RSVNUM,
                        }
                        let getAPIINSORSV = await serviceAPI.call_API('post',`wholesale/insertORDRESERVEVOID`,dataINSORSV,'auth');
                    }

                    let dataINSIN={
                        BATCHID: BATCHID,
                    }
                    let getAPIINSIN = await serviceAPI.call_API('post',`wholesale/intfORDRESERVEVOIDDIRECTSALES`,dataINSIN,'auth');
                    console.log(getAPIINSIN);
                    this.listdataMMSRES = getAPIINSIN.data.dbitems[getAPIINSIN.data.dbitems.length -1]
                    let listerror = getAPIINSIN.data.dbitems
                    console.log(listerror);
                    console.log(this.listdataMMSRES);
                    if(getAPIINSIN.data.dbcode > 0){
                        throw new Error(getAPIINSIN.data.dbmessage);
                    }
                    if(this.listdataMMSRES.RESULT != 'OKOK'){
                        throw new Error(this.listdataMMSRES.RESULT)
                    }
                    let dataUPDATEMMS={
                        REF: REF, 
                        MMSNO: ''
                    }
                    await serviceAPI.call_API('post',`wholesale/updateQTReserveMMSNo`,dataUPDATEMMS,'auth');
                } catch (error) {
                    this.WaitBooking = false;
                    serviceMain.showErrorAlert(this,error)
                }
            },
            async goOTPREQ(){
                this.$router.push({ name: 'OTPRequest', params: { cartRef: this.cardData.REF }, query: { stcode: this.cardData.stcode, OTPreq: this.cardData.OTPreq, whereBy:this.whereByData }})
            },
            async setreloadcard() {
                try{
                this.$emit("setreloadcard");
                } catch (error) { 
                serviceMain.showErrorAlert(this,error)
                }
            },
            async sendUpdateJobWholeSale() {
                try {
                    let data = {
                        JobNo: this.cardData.COPYFROM,
                        REF : this.cardData.REF,
                        AMT : Number(this.cardData.amount.replace(/[^0-9.-]+/g,""))
                    }
                    console.log(data);
                    let getAPI = await serviceAPI.call_API('post', `wholesale/sendUpdateJobWholeSale`, data, 'auth');
                    console.log(getAPI);
                    if(getAPI.data.data.IsSuccess){
                        serviceMain.showSuccessAlertWithTime(this,`${this.cardData.COPYFROM} ${getAPI.data.data.Message}`,3000)
                    }else{
                        serviceMain.showWarningAlert(this,`${this.cardData.COPYFROM} ${getAPI.data.data.Message}`)
                    }

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async reSendMail(REF) {
                try{
                let _this = this
                this.$swal.fire({
                    html: '<h2>ต้องการยืนยันส่ง Mail Approve ใหม่ ?</h2>',
                    showCancelButton: true,
                    confirmButtonColor: this.getBuColor,
                    confirmButtonText: 'ตกลง'
                }).then(async (result) => {
                    if (result.value) {
                    let data ={
                        quotationNO: REF,
                        HOSTNAME:`${location.protocol}//${location.host}`
                    }
                    await serviceAPI.call_API('post', `directsale/reSendApproveMail`, data, 'auth');
                    // _this.$emit('conQT')
                    // _this.getCurrentBooking()
                    serviceMain.showSuccessAlertWithTime(this,`ส่ง Mail ยืนยันใบเนอราคาแล้ว`,3000)
                    // location.reload(true);
                    }
                }).catch((error) => {
                    serviceMain.showErrorAlert(this, error)
                });
                } catch (error) {
                serviceMain.showErrorAlert(this, error)
                }
            },
        },
        computed: {
            getBU: function () {
                return this.$store.getters['customer/getQTBU']
            },
            getCopyRef:function(){
                return this.$store.getters['customer/getQTCOPYREF']
            },
            listdata: function () {
                return this.cardData
            },
            getQTCUSTINFO: function () {
                return this.$store.getters['customer/getQTCUSTINFO']
            },
            StoreCode: function () {
                return this.$store.getters['service/getQTDFSTORE']
            },
            getQTSALESOURCE: function () {
                return this.$store.getters['auth/getQTSALESOURCE']
            },
            getQTPROFILE: function () {
                return this.$store.getters['auth/getQTPROFILE'];
            },
            getQTSUBSALESOURCE: function () {
                return this.$store.getters['auth/getQTSUBSALESOURCE']
            },
            getBuColor:function(){
            let getBU = this.$store.getters['customer/getQTBU']
            let color = '';
            switch(getBU) {
                case 'bnb':
                color = '#006e63';
                break;
                case 'at1':
                color = '#3866af';
                break;
                default:
                color = '#55246c';
            }
            return color;
            },
            getQTOTP: function () {
                return this.$store.getters['auth/getQTOTP']
            },
            canVoid:function(){
                let status = this.cardData.sostatus
                let track = this.cardData.trackingnumber
                return ['C','W'].includes(status) && track != ''
            },
            expDate:function(){
                let nowDate = new Date();
                let expireDate = new Date(this.cardData.qtexpired ? this.cardData.qtexpired.replace(' ', 'T'): null);
                if(expireDate < nowDate){
                    return false
                } else {
                    return true
                }
            },
            checkStatus:function(){
                if (['P', 'Q', 'R', 'O', 'D', '', 'V', 'U', 'N'].includes(this.cardData.sostatus)) {
                    return false
                }else{
                    let nowDate = new Date();
                    let expireDate = new Date(this.cardData.qtexpired ? this.cardData.qtexpired.replace(' ', 'T'): null);
                    if(expireDate < nowDate){
                        return false
                    }else{
                        return true
                    }
                }
                // if(this.storeUnusePaylater.includes(this.stcode)){
                // this.btnPaylaterName = "สาขายกเว้น"
                // this.canPay=false
                // }
            },
            canOTP:function(){
                let status = this.cardData.sostatus
                let flag = this.cardData.OTPflag
                return status == 'C'
            },
            isOTP:function(){
                let flag = this.cardData.OTPflag
                return flag != ''
            },
            isPaylater:function(){
                let flag = this.cardData.OTPflag
                return flag != ''
            },
            OTPreqFlag:function(){
                let flag = this.cardData.OTPreq
                return flag != ''
            },
            OTPappvFlag:function(){
                let flag = this.cardData.OTPappv == '0' || this.cardData.OTPappv == 'false'
                return flag
            },
            PaylaterFlag:function(){
                let flag = this.cardData.Paylater
                return flag != ''
            },
            qtapprovedDate:function(){
                if(this.cardData.qtapproved){
                    let qtapproved = new Date(this.cardData.qtapproved)
                    let dd = qtapproved.getUTCDate().toString();
                    dd = dd.length < 2 ? '0'+dd : dd;

                    let mm = (qtapproved.getUTCMonth() + 1).toString();
                    mm = mm.length < 2 ? '0'+mm : mm;

                    let yyyy = qtapproved.getFullYear().toString();
                    let HH = qtapproved.getUTCHours().toString();
                    HH = HH.length < 2 ? '0'+HH : HH;
                    let MM = qtapproved.getUTCMinutes().toString();
                    MM = MM.length < 2 ? '0'+MM : MM;
                    let SS = qtapproved.getUTCSeconds().toString();
                    SS = SS.length < 2 ? '0'+SS : SS;

                    return dd+'/'+mm+'/'+yyyy+' '+HH+'.'+MM+'.'+SS;
                }
            },
            getWSFLAG: function () {
                return this.$store.getters['auth/getWSFLAG']
            },
            getWSJOB: function () {
                return this.cardData.COPYFROM.search("WS") >= 0 && ['C', 'P', 'Q', 'R', 'O', 'D'].includes(this.cardData.sostatus)
            },
            canCopySalesource: function() {
                let profile = this.$store.getters['auth/getQTPROFILE'];
                return profile ? profile.includes('0145') : false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .card-history {
        background-color: white;
        margin-bottom: 15px;
    }

    @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

    button:disabled,
    button[disabled]{
        cursor: not-allowed;
        background-color: lightgray !important;
    }

    .track {
        position: relative;
        background-color: none;
        height: 1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 50px;
        margin-top: 40px;
    }

    .line {
        position: relative;
        background-color: #CCC;
        height: 1px;
        width: 40%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .track .step {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 14%;
        margin-top: -18px;
        text-align: center;
        position: relative
    }

    .track .step.active .icon {
        background: #5cb85c;
        color: #fff;
        padding-top: 5px;
        font-size: 18px;
    }

    .track .step.cancel .icon {
        background: #55246c;
        color: #fff
    }

    .track .step.none .icon {
        background: none;
        color: #fff
    }

    .track .icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: relative;
        border-radius: 100%;
        background: #ddd
    }

    .icon>.fa {
        font-size: 15px !important;
        margin-top: 8px;
    }

    .track .step.active .text {
        font-weight: 400;
        color: #000;
    }

    .lineca.none {
        background: none;
    }

    .track .text {
        font-size: 15px !important;
        display: block;
        margin: 7px -20px 0 -20px;
    }

    .track .textr {
        font-size: 15px !important;
        display: block;
        margin: 7px -20px 0 -20px;
    }

    .w15pb03 {
        width: 15%;
        padding-bottom: .3em;
    }

    .w15pb03img {
        width: 15%;
        padding-bottom: .3em;
    }

    .w40 {
        width: 40%;
        padding-bottom: .3em;
    }

    .btn-v-green {
        border: none;
        width: 100%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border:none;
        cursor: pointer;
        border-radius: 4px;
        background-color: #5cb85c;
        color: white;
        font-weight: 900;
        &:hover {
            background-color: darken( #5cb85c, 20% )
        }
    }
    .btn-v-yellow {
        border: none;
        width: 100%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border:none;
        cursor: pointer;
        border-radius: 4px;
        background-color: #DFFF00;
        color: white;
        font-weight: 900;
        &:hover {
            background-color: darken( #DFFF00, 20% )
        }
    }
    .btn-v-red {
        border: none;
        width: 100%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border:none;
        cursor: pointer;
        border-radius: 4px;
        background-color: #FF0000;
        color: white;
        font-weight: 900;
        &:hover {
            background-color: darken( #FF0000, 20% )
        }
    }

    @media screen and (max-width: 1250px) {

    }

    @media screen and (max-width: 992px) {
        .text-sm-center {
            text-align: center;
        }

        .p-sm-0 {
            padding: 0;
        }
    }

    @media screen and (max-width: 768px) {

        .track .textr {
            margin: 7px -15px 0 -15px;
        }

        .w15pb03img {
            width: 30%;
            padding-bottom: .3em !important;
        }

        .w40 {
            width: 70%;
        }

        .pm-xs-0 {
            padding: 0;
            margin: 0;
        }

    }
    

    $color-PWB: #55246c;
    $color-bnb: #006e63;
    $color-at1: #3866af;
    $listBu: 'PWB' $color-PWB, 'bnb' $color-bnb, 'at1' $color-at1;

    @each $buItems in $listBu {
        button.btn-visible-#{nth($buItems, 1)}:disabled,
        button[disabled].btn-visible-#{nth($buItems, 1)} {
            background-color: nth($buItems, 2);
            cursor: default;
        }
    }
</style>