<template>
  <section>
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <h4 class="font-weight-bold">รายการคำสั่งซื้อ</h4>
      </div>
      <div class="col-12 col-md-6">
        <div class="input-group" v-if="['ByFind'].includes(whereBy)">
          <input v-model="searchTxt" type="text" class="form-control" v-on:keyup.enter="startSearch"
            placeholder="ค้นหาด้วยเบอร์หรือเลขใบเสนอราคา">
          <div class="input-group-btn">
            <button @click="startSearch" :disabled="isLoading" :class="`btn bg-${getBU} ${!isLoading ? '': 'disabled'}`">
              <span class="glyphicon glyphicon-search" style="color: white;">ค้นหา</span>
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="row ">
        <div v-if="['WaitApproveByAdmin'].includes(whereBy) || ['WaitApproveByUser'].includes(whereBy)" class="col-12">
          <div v-if="checkCard">
            <p>ไม่มีรายการคำสั่งซื้อ</p>
          </div>
        </div>
        <div class="col-12" v-show="isLoading">
          <HistoryCardSkeleton />
        </div>
      <div class="col-12" v-for="e in listData" :key="e.REF" v-show="!isLoading">

        <HistoryCard :cardData="e" :whereByData="whereBy" @conQT="startHistory()" @setreloadcard="setreloadcard"/>
        <!-- <HistoryCard v-if="['ByCustomer','ByUser'].includes(whereBy)" :cardData="e"/> -->
      </div>

    </div>
    <div v-if="listData != ''" class="row">
      <div class="col-12">
        <div class="row pt-1 d-flex justify-content-center">
          <div class="col-5" style="padding: 0px 5px 0px 5px;">
            <button @click="minus" type="button" class="d-flex justify-content-between w-100" style="border-radius: 0;">
              <p class="align-self-center m-0"><i class="fa fa-arrow-left"></i></p>
              <p class="align-self-center m-0">ก่อนหน้านี้</p>
            </button>
          </div>
          <div class="col-5 " style="padding: 0px 5px 0px 5px;">
            <button @click="plus" type="button" class="d-flex justify-content-between w-100" style="border-radius: 0;">
              <p class="align-self-center m-0">ต่อไป</p>
              <p class="align-self-center m-0"><i class="fa fa-arrow-right"></i></p>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center" style="padding-top: 2px;">
            รายการคำสั่งที่ {{firstPage}}-{{lastPage}} จากทั้งหมด {{ MaxCount }} รายการ
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLoading" class="loading"></div>
  </section>
</template>


<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import HistoryCard from '@/components/Menu/HistoryCard.vue'
  import HistoryCardSkeleton from '@/components/Menu/HistoryCardSkeleton.vue'
  export default {
    name: 'HistoryList',
    components: {
      HistoryCard,
      HistoryCardSkeleton
    },
    props: [
      "whereBy"
    ],
    data: function () {
      return {
        listData: [],
        isLoading: false,
        startCount: '0',
        wantShowCount: 4,
        isZeroRow: false,
        MaxCount: null,
        searchTxt: '',
        nowPage: 1,
        firstPage:1,
        lastPage:1,
        maxPage: 0,
        checkCard:false,

      }
    },
    mounted() {
      this.startHistory()
    },
    methods: {
      async startHistory() {
        try {
          if (this.whereBy == 'ByFind') {
            let getQueryQT = this.$route.query.QT;
            if(getQueryQT){
              this.searchTxt=getQueryQT
              this.isLoading = true
              this.countHistory()
            }
          }else{
            this.isLoading = true
            this.countHistory()
          }
        } catch (error) {
        this.isLoading = true
          serviceMain.showErrorAlert(this, error.message)
        }

      },
      async startSearch() {
        this.isLoading = true
        this.countHistory()
      },
      async countHistory() {
        try {
          if (["ByCustomer"].includes(this.whereBy)) {
            if (!this.getQTCUSTINFO.MOBILE) {
              if(!this.$store.getters['auth/getWSFLAG']){
                this.$router.push({ name: 'FindUser'})
              }
              throw new Error('กรุณาเลือกลูกค้าก่อนครับ')
            }
          }

          if (["ByFind"].includes(this.whereBy)) {
            if(this.searchTxt.length<10){
              throw new Error('กรุณากรอกค้นหาด้วยเบอร์หรือเลขใบเสนอราคา')
            }
          }

          let data = {
            USER: this.getQTUSERID,
            MOBILE: this.getQTCUSTINFO.MOBILE,
            SEARCH: this.searchTxt
          }
          let getAPI = await serviceAPI.call_API('post', `history/Count${this.whereBy}`, data, 'auth');
          let getAllitem = getAPI.data.dbitems[0].allitem;
          this.MaxCount = getAllitem;
          this.cal()
        } catch (error) {
          this.isLoading = false
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async listHistory() {
        try {

          let data = {
            USER: this.getQTUSERID,
            MOBILE: this.getQTCUSTINFO.MOBILE,
            OFFSET: this.startCount,
            LIMIT: this.wantShowCount,
            SEARCH: this.searchTxt

          }
          let getAPI = await serviceAPI.call_API('post', `history/${this.whereBy}`, data, 'auth');
          this.listData = getAPI.data.dbitems;
          this.isLoading = false
          if( this.listData[0] == undefined){
            this.checkCard = true
          }

        } catch (error) {
        this.isLoading = false
          serviceMain.showErrorAlert(this, error.message)
        }
      },

      plus() {
        if (this.nowPage >= this.maxPage) {
          this.nowPage = this.maxPage;
          return;
        }
        this.nowPage = this.nowPage + 1;
        this.isLoading = true
        this.cal();
      },

      minus() {
        this.nowPage = this.nowPage - 1;
        if (this.nowPage <= 0) {
          this.nowPage = 1;
        }
        this.isLoading = true
        this.cal();
      },

      cal() {
        let countData = this.MaxCount;
        let limit = this.wantShowCount;
        let maxPage = Math.ceil(countData / limit);
        this.maxPage = maxPage;

        this.startCount = (this.nowPage * this.wantShowCount) - this.wantShowCount;
        this.listHistory()

        this.firstPage = this.startCount + 1;
        this.isLoading = true
        if(limit >  countData ){
          this.lastPage = countData;
        } else{
        this.lastPage = this.startCount + this.wantShowCount;
        }
      },

      async setreloadcard() {
          try{
            this.startHistory()
          } catch (error) { 
          serviceMain.showErrorAlert(this,error)
          }
      },

    },
    computed: {
      getQTUSERID: function () {
        return this.$store.getters['auth/getQTUSERID']
      },
      getQTCUSTINFO: function () {
        return this.$store.getters['customer/getQTCUSTINFO']
      },
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    },
  }
</script>

<style scoped lang="scss">
button:disabled,
button[disabled]{
  pointer-events: none;
  cursor: not-allowed;
}
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
</style>