<template>
  <section>
    <button @click="openModal" :disabled="!canPay" type="button" :class="`btn-visible-${buCode}`" >ยืนยัน PR No.</button>

    <Modal ref="ConfirmPRNo" size="80" :buCode="buCode">

      <template v-slot:header>
        <p>รายระเอียดใบเสนอราคา</p>
      </template>
      <template v-slot:body>
        <!-- <div class="container"> -->
        <div class="row px-3 m-0 mt-3">
          <div class="col-12">
            <p class="mr-0 h4 checkout-detail-title px-0 mx-0" style="float: right;">เลขที่รายการ {{ listData.REF }} สาขา {{ listData.STORE }}</p>
          </div>
          <div class="col-12 col-md-8">
            <div class="row">
              <!-- <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-3">
                    <span class=" mr-0 h6 checkout-detail-title" style="float: left;">ชื่อผู้รับสินค้า: </span>
                  </div>
                  <div class="col-9">
                    <input class="w-100 mr-0 h6 checkout-detail-title" style="float: left;" maxlength="80" placeholder="" v-model="pickName" :disabled="!reserveNo == ''">
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-3">
                    <span class=" mr-0 h6 checkout-detail-title" style="float: left;">ทะเบียนรถ: </span>
                  </div>
                  <div class="col-9">
                    <input class="w-100 mr-0 h6 checkout-detail-title" style="float: left;" maxlength="80" placeholder="" v-model="carNo" :disabled="!reserveNo == ''">
                  </div>
                </div>
              </div> -->
              <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class=" mr-0 px-0 h6 checkout-detail-title" style="float: left;">ชื่อผู้รับสินค้า: </span>
                  </div>
                  <div class="col-12 col-md-9">
                    <input class="w-100 mr-0 h6 checkout-detail-title" style="float: left;" maxlength="80" placeholder="" v-model="receiverName" :disabled="!reserveNo == ''">
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class=" mr-0 px-0 h6 checkout-detail-title" style="float: left;">ทะเบียนรถรับสินค้า: </span>
                  </div>
                  <div class="col-12 col-md-9">
                    <input class="w-100 mr-0 h6 checkout-detail-title" style="float: left;" maxlength="80" placeholder="" v-model="licensePlate" :disabled="!reserveNo == ''">
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class=" mr-0 px-0 h6 checkout-detail-title" style="float: left;">หมายเลข PR: </span>
                  </div>
                  <div class="col-12 col-md-9">
                    <input class="w-100 mr-0 h6 checkout-detail-title" style="float: left;" maxlength="80" placeholder="" v-model="PRNO" :disabled="!reserveNo == ''">
                  </div>
                </div>
              </div>
              <div class="col-12">
                <input type="file" id="prno-btn" accept="application/pdf" @change="previewFiles" hidden/>
                <label for="prno-btn" :class="`btn-visible-${buCode}`" style="float: left;" >Choose File</label>
                <span class="px-2" style="float: left;">{{fileNamePR}}</span>
              </div>
              <div class="col-12 mb-2">
                <span class="px-2" style="float: left;">ขนาดไฟล์ต้องไม่เกิน 10 MB</span><br>
                <span class="px-2" style="float: left;">รองรับไฟล์ PDF</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <table class="table table-bordered" >
              <tbody>
                <tr>
                  <td>เครดิตคงเหลือ</td>
                  <td>{{ priceBaht(creditRemain) }} บาท</td>
                </tr>
                <tr>
                  <td>ราคารวม Ex.Vat</td>
                  <td>{{ priceBaht(priceBeforeVat) }} บาท</td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>{{ priceBaht(priceVat) }} บาท</td>
                </tr>
                <tr>
                  <td>ราคารวมสุทธิ</td>
                  <td>{{ amountBaht }} บาท</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row px-3 m-0 mt-3 mb-3">
          <div class="col-8"></div>
          <div class="col-4">
            <button @click="reserveCreditDS()" :class="`btn-visible-${buCode} ${canReserve ? '':'disabled'}`" :disabled="!canReserve">ยืนยัน</button>
          </div>
          <!-- <div class="col-4">
            <button @click="updatePRconfirm()" :class="`btn-visible-${buCode} ${canReserve ? '':'disabled'}`" :disabled="!canReserve">test</button>
          </div> -->
        </div>
        <!-- <div class="row px-3 m-0 mt-3">
          <div lass="col-8 m-2">
            <div class="container w-100">
            <div class="row">
              <div class="col-12">
                <span class=" mr-0 h6 checkout-detail-title" >หมายเลข PR: </span>
              </div>
              <div class="col-12">
                <input class="w-50 mr-0 h6 checkout-detail-title" maxlength="80 " placeholder="" name="Prno" v-model="PRNO" :disabled="!reserveNo == ''">
              </div>
            </div>
            </div>
          </div>
          <div lass="col-4 m-2">
            <span class="mr-0 h4 checkout-detail-title" >เลขที่รายการ {{ listData.REF }}</span><br> 
            <span class="mr-0 h6 checkout-detail-title" >สาขา {{ listData.STORE }}</span>
            <table class="table table-bordered" >
              <tbody>
                <tr>
                  <td>เครดิตคงเหลือ</td>
                  <td>{{ priceBaht(creditRemain) }}</td>
                  <td>บาท</td>
                </tr>
                <tr>
                  <td>ราคารวม Ex.Vat</td>
                  <td>{{ priceBaht(priceBeforeVat) }}</td>
                  <td>บาท</td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>{{ priceBaht(priceVat) }}</td>
                  <td>บาท</td>
                </tr>
                <tr>
                  <td>ราคารวมสุทธิ</td>
                  <td>{{ amountBaht }}</td>
                  <td>บาท</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <!-- <div class="col-12 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่รายการ {{ listData.REF }} {{ listData.SALESOURCE }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >สาขา {{ listData.STORE }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >ราคารวม Ex.Vat {{ priceBaht(priceBeforeVat) }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >VAT {{ priceBaht(priceVat) }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >ราคารวมสุทธิ {{ amountBaht }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >CustomerID DS {{ listData.CUSTIDDS }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" > creditRemain {{ priceBaht(creditRemain) }} บาท</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่จองสินค้า {{ listData.reserveMMSNo }}</span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >เลขที่ชำระเครดิต : {{ reserveNo }} </span>
          </div>
          <div class="col-12  p-0 mb-2">
            <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >วันที่ชำระเครดิต : {{ reserveDate }}</span>
          </div>
          <div class="col-12 p-0 mb-2" >
            <div class="row">
              <div class="col-12">
                <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >PR No : </span>
                <input class="w-50 font-kitti-bold mr-0 h6 checkout-detail-title" maxlength="50" placeholder="" name="Prno" v-model="PRNO" :disabled="!reserveNo == ''">
              </div>
            </div>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="reserveCreditDS()" :class="`btn-visible-${buCode} ${canReserve ? '':'disabled'}`" :disabled="!canReserve">{{btnCreditName}}</button>
          </div> -->
          <!-- <div class="col-12  p-0 mb-2">
            <button @click="checkReserveCreditDS()" :class="`btn-visible-${buCode}`" :disabled="!canCancelReserve">เช็คเครดิต</button>
          </div> -->
          <!-- <div class="col-12  p-0 mb-2">
            <button @click="cancelReserveCreditDS()" :class="`btn-visible-${buCode}`" :disabled="!canCancelReserve">ยกเลิกเครดิต</button>
          </div>
          <div class="col-12  p-0 mb-2">
            <button @click="reserveStockMMS()" :class="`btn-visible-${buCode}`" >testreserve</button>
          </div> -->
        
        <!-- </div> -->
        <!-- </div> -->
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import * as constant from '@/services/constant';
import QrcodeVue from 'qrcode.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: "ConfirmPRNo",
  data() {
    return {
      linkPaylater: '',
      mobile:'',
      smsTxt:'',
      bu:'',
      cname:'',
      custid:'',
      btnCreditName:'ชำระเครดิต',
      canPay:false,
      listData:[],
      reserveNo:'',
      reserveDate:'',
      creditRemain:'',
      creditPermanenct: '',
      creditTemp: '',
      creditTotal: '',
      PRNO: '',
      listItems:[],
      resStockMMSStatus: false,
      BATCHID: '',
      listdataMMSRES: [],
      listDataAMT: [],
      priceVat: 0,
      priceBeforeVat:0,
      filePR:'',
      fileNamePR:'No file chosen',
      base64File:'',
      receiverName:'',
      licensePlate:'',
      PlppPRNO:[]
    };
  },
  components: {
    QrcodeVue,
    Modal
  },
  props: [
    "cartref",
    "sostatus",
    "sorderref",
    "stcode",
    "amount",
    "qtexpired",
    "buCode",
    "qtapproved"
  ],
  mounted() {
      // this.getOrderDetail()
      this.getDetail4ReserveCredit()
  },
  methods: {
    async getDetail4ReserveCredit() {
        try {
            let data = {
                REF:this.cartref,
            }
            let getAPI = await serviceAPI.call_API('post', `directsale/getDetail4ReserveCreditB2B`, data, 'auth');

            let getAPI2 = await serviceAPI.call_API('post', `history/getPriceAndRemarkByVfix`, { "ref": this.cartref }, 'auth');

            let getAPI3 = await serviceAPI.call_API('post', `directsale/getPlppPRNO`, data, 'auth');

            this.listDataAMT = getAPI2.data.dbitems[0];

            this.PlppPRNO = getAPI3.data.dbitems[0]
            // console.log(this.PlppPRNO);

            // console.log(getAPI);
            this.listData = getAPI.data.dbitems[0];
            this.reserveNo = getAPI.data.dbitems[0].reserveNo || ''
            this.reserveDate = getAPI.data.dbitems[0].reserveDate || ''
            this.PRNO = getAPI.data.dbitems[0].PRNO || ''
            this.listItems = JSON.parse(getAPI.data.dbitems[0].JSNITEMS)
            this.receiverName = getAPI.data.dbitems[0].receiverName || ''
            this.licensePlate = getAPI.data.dbitems[0].licensePlate || ''
            this.fileNamePR = getAPI.data.dbitems[0].fileBase64 || ''

            let totallist = this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0
            let priceamount = this.listDataAMT.amount ? await this.price2Num(this.listDataAMT.amount) : 0
            let amt = parseFloat(totallist);
            let beforeVat = amt/1.07
            let vat = amt-beforeVat
            this.priceVat = vat
            this.priceBeforeVat = priceamount - vat

            this.checkStatus()
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },

    previewFiles(event) {
      // call them as such; files[0].size will get you the file size of the 0th file
      // for (var x in files) {
      let _this = this
      var filesize = ((event.target.files[0].size/1024)/1024).toFixed(4); // MB
      if (event.target.files[0].name != "item" && typeof event.target.files[0].name != "undefined" && filesize <= 10) { 
        this.filePR = event.target.files[0]
        this.fileNamePR = event.target.files[0].name
        var files = document.getElementById("prno-btn").files;
        if (files.length > 0) {

            var fileToLoad = files[0];
            var fileReader = new FileReader();
            // this.base64File = '';
            // Reading file content when it's loaded
            fileReader.onload = function(event) {
                _this.base64File = event.target.result;
                // base64File console
            };

            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
      }
      // }
      // $("#approvedFiles").val(approvedHTML);
      // if(event.target.files){
      //   this.filePR = event.target.files[0]
      //   this.fileNamePR = event.target.files[0].name
      // }
    },

    priceBaht(para_price) {
      return para_price.toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    async price2Num(para_price) {
      return Number(para_price.replace(/[^0-9.-]+/g,""))
    },

    async checkCreditDS(){
      try {
        if(this.listData.CUSTIDDS.trim() != '9999990189'){
          
        let data={
          REF:this.cartref,
          STCODE:this.listData.STORE,
          CustomerDSID:this.listData.CUSTIDDS.trim()
        }
        let getAPI = await serviceAPI.call_API('post',`directsale/checkCreditDS`,data,'auth');
        // console.log(JSON.parse(getAPI.data.data));
        let getData = JSON.parse(getAPI.data.data);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        
        this.creditRemain = getData.data.creditremain
        this.creditPermanenct = getData.data.creditpermanenct
        this.creditTemp = getData.data.credittemp
        this.creditTotal = getData.data.credittotal
        }else{
          this.creditRemain = 100000000
          this.creditPermanenct = 0
          this.creditTemp = 100000000
          this.creditTotal = 100000000
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkStatus() {
        if (['', 'C', 'T', 'B', 'U', 'V', 'N'].includes(this.sostatus)) {
            this.canPay=false
        }else{
          this.canPay=true
        }
    },

    async openModal(){
      try {
          // await this.checkStatus()
          // if(!this.canPay){
          //   throw new Error(this.btnCreditName)
          // }
          this.$refs.ConfirmPRNo.showmodal()
          // await this.getDetail4ReserveCredit()
          await this.checkCreditDS()
          // this.startPaylater()
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
    },

    async reserveCreditDS(){
      try {
        if(!this.PRNO) {
          serviceMain.showWarningAlert(this,'กรุณาระบุค่า PR No')
          return;
        }
        // ปิด reserveCreditDS
        // if(this.listData.CUSTIDDS.trim() != '9999990189'){
        //   let data={
        //     REF:this.cartref,
        //     SALESOURCE:this.listData.SALESOURCE,
        //     STCODE:this.listData.STORE,
        //     AMOUNT:this.listData.AMOUNT,
        //     CustomerDSID:this.listData.CUSTIDDS.trim()
        //   }
        //   let getAPI = await serviceAPI.call_API('post',`wholesale/reserveCreditDS`,data,'auth');
        //   let getData = JSON.parse(getAPI.data.data);
        //   // console.log(getData);
        //   if(getData.status == 0){
        //     throw new Error(getData.message)
        //   }

        //   this.reserveNo = getData.reserveNo
        //   this.reserveDate = getData.reserveDate
        //   this.creditRemain = getData.creditRemain
        // } else {
        //   this.reserveNo = 'XXXXXXXXXXXXX'
        //   this.reserveDate = new Date()
        //   this.creditRemain = 100000000
        // }

        this.reserveNo = 'XXXXXXXXXXXXX'
        this.reserveDate = new Date()
        // this.creditRemain = 100000000

        // await this.reserveStockMMS()
        // if(this.resStockMMSStatus != true){
        //   this.cancelReserveCreditDS()
        // }

        let JSNCREDIT = {
          docNo: '',
          wholeSaleCustomerId: '',
          directSaleCustomerId:this.listData.CUSTIDDS.trim(),
          directSaleMMSStoreCode: this.listData.STORE,
          reserveNo: this.reserveNo,
          reserveDate: this.reserveDate,
          prNo: this.PRNO,
          // reserveMMSNo: this.listdataMMSRES.HOLDREF
          reserveMMSNo: this.listData.reserveMMSNo,
          prFileUpload:{
              // fileBase64: this.base64File,
              fileBase64: this.fileNamePR,
              fileExtension:".pdf"
          },
          licensePlate: this.licensePlate,
          receiverName: this.receiverName,
          APVUSER: this.listData.APVUSER || ''
        }
        let JSNPOSREF = [this.reserveNo]
        let datapaid={
          REF:this.cartref ,
          JSNCREDIT:JSNCREDIT,
          JSNPOSREF:JSNPOSREF
        }
        // console.log(datapaid);
        let getAPIpaid = await serviceAPI.call_API('post',`directsale/paidByCredit`,datapaid,'auth');
        var getsorder = getAPIpaid.data.dbitems;
        // console.log(getsorder);
        serviceMain.showSuccessAlertWithTime(this,`การยืนยันเรียนร้อย`,3000)
        this.$emit("setreloadcard");
        this.checkCreditDS()
        location.reload(true);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async updatePRconfirm(){
      try {
        let JSNITEMS = []
        for (const e of this.listItems) {
          JSNITEMS.push({
            orderSeq: e.SEQ,
            skuCode: e.SKC,
            upcCode: e.PCD,
            quant: e.QNT,
            itemCost: e.COST,    //มาจากjsnitems.COST
            itemUnitPrice: e.UPC,  //มาจาก jsnitems.UPC
            itemTotalAmounts: e.AMT  //มาจาก jsnitems.AMT
          })
        }
        let data={
          SALESOURCE: this.listData.SALESOURCE,
          REF: this.cartref,
          prNumber: this.PRNO,
          prFileUpload:{
              // fileBase64: this.base64File,
              fileBase64: this.fileNamePR,
              fileExtension:".pdf"
          },
          licensePlate: this.licensePlate,
          receiverName: this.receiverName,
          totalAmount: this.listDataAMT.amount,    //มาจาก  exec TWDQT..qt_getPriceAndRemarkByVfix 'QT2208000151'
          totalDiscount: this.listDataAMT.discount,   //มาจาก  exec TWDQT..qt_getPriceAndRemarkByVfix 'QT2208000151'
          dataItemDiscount: this.PlppPRNO.dataItemDiscount,   //มาจาก EX1 ท้ายเอกสาร ส่ง dataItemDiscount จาก EX1 เป็น String มาได้เลย
          dataBillAvgItemDiscount: this.PlppPRNO.dataBillAvgItemDiscount,  //มาจาก EX1  ท้ายเอกสาร ส่ง dataBillAvgItemDiscount จาก EX1 เป็น String มาได้เลย
          items : JSNITEMS
        }
        console.log(data);
        // console.log(datapaid);
        let getAPIpaid = await serviceAPI.call_API('post',`directsale/sendUpdatePRNO`,data,'auth');
        // var getsorder = getAPIpaid.data.dbitems;
        // // console.log(getsorder);
        // serviceMain.showSuccessAlertWithTime(this,`การยืนยันเรียนร้อย`,3000)
        // this.$emit("setreloadcard");
        // this.checkCreditDS()
        // location.reload(true);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async conPRNo(){
      try {
        let data={
          RESERVENO:this.reserveNo,
          RESERVEDATE:this.reserveDate,
          STCODE:this.listData.STORE
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkReserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        serviceMain.showSuccessAlertWithTime(this,`การจองเครดิต ${getData.reserveNo} มูลค่า ${getData.amount} สถานะ ${getData.reserveStatus}`,3000)
        this.reserveNo = getData.reserveNo
        this.reserveDate = getData.reserveDate
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkReserveCreditDS(){
      try {
        let data={
          RESERVENO:this.reserveNo,
          RESERVEDATE:this.reserveDate,
          STCODE:this.listData.STORE
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkReserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        serviceMain.showSuccessAlertWithTime(this,`การจองเครดิต ${getData.reserveNo} มูลค่า ${getData.amount} สถานะ ${getData.reserveStatus}`,3000)
        this.reserveNo = getData.reserveNo
        this.reserveDate = getData.reserveDate
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async cancelReserveCreditDS(){
      try {
        let data={
          RESERVENO:this.reserveNo,
          RESERVEDATE:this.reserveDate,
          STCODE:this.listData.STORE
        }
        let getAPIcheck = await serviceAPI.call_API('post',`wholesale/checkReserveCreditDS`,data,'auth');
        let getDataCheck = JSON.parse(getAPIcheck.data.data);
        // console.log(getDataCheck);
        if(getDataCheck.status == 0){
          throw new Error(getDataCheck.message)
        }
        if(getDataCheck.reserveStatus != 'Y'){
          throw new Error(`${this.reserveNo} สถานะยกเลิก`)
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/cancelReserveCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        // serviceMain.showSuccessAlertWithTime(this,`ยกการการจองเครดิต ${getData.reserveNo} สำเร็จ`,3000)
        this.reserveNo = ''
        this.reserveDate = ''
        this.creditRemain = ''
        this.$emit("setreloadcard");
        this.checkCreditDS()

      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async reserveStockMMS(){
      try {
        if(!this.reserveNo){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error reserveNo)')
        }
        // let dataSTORE={
        //   store: this.listData.STORE
        // }
        // let getAPISTORE = await serviceAPI.call_API('get',`service/getSTORE`,dataSTORE,'auth');
        // let store = getAPISTORE.dbitems[0].STORE;
        

        let data={
          REF: this.cartref
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/getBatchID`,data,'auth');
        this.BATCHID = getAPI.data.dbitems[0].BATCH_ID;
        if(!this.BATCHID){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error BATCHID)')
        }

        await this.deleteORDRESERVETEMP()

        for (let i = 0; i < this.listItems.length; i++) {
          let dataINSORS={
            REF: this.cartref, 
            BATCHID: this.BATCHID, 
            STCODE: this.listData.STORE, 
            UPC: this.listItems[i].PCD, 
            QTY: this.listItems[i].QNT, 
            RETL: this.listItems[i].AMT, 
            ORDERNO: this.reserveNo, 
            SEQ: this.listItems[i].SEQ,
            SALESOURCE: this.listData.SALESOURCE
          }
          let getAPIINSORS = await serviceAPI.call_API('post',`wholesale/insertORDRESERVE`,dataINSORS,'auth');
        }

        let dataINSIN={
          BATCHID: this.BATCHID,
        }
        let getAPIINSIN = await serviceAPI.call_API('post',`wholesale/intfORDRESERVEDIRECTSALES`,dataINSIN,'auth');
        console.log(getAPIINSIN);
        this.listdataMMSRES = getAPIINSIN.data.dbitems[0]
        if(getAPIINSIN.data.dbcode > 0){
          this.resStockMMSStatus =  false
          throw new Error(getAPIINSIN.data.dbmessage);
        }
        if(this.listdataMMSRES.RESULT != 'OKOK'){
          this.resStockMMSStatus =  false
          throw new Error(this.listdataMMSRES.RESULT);
        }

        this.resStockMMSStatus = true
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
        this.resStockMMSStatus =  false
      }
    },

    async deleteORDRESERVETEMP(){
      try {
        let data={
          BATCHID: this.BATCHID,
        }
        await serviceAPI.call_API('post',`wholesale/deleteORDRESERVE`,data,'auth');
        // console.log(getData);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getQTPLT:function(){
        return this.$store.getters['auth/QTPLT']
      },
      qtShopItemLimit: function() {
        return this.$store.getters["customer/getQTSHOPITEM_LIMIT"];
      },
      canReserve:function(){
        if(this.listData.CUSTIDDS && this.PRNO && this.receiverName && this.licensePlate && !this.reserveNo && !this.reserveDate){
          if(this.creditRemain >= this.listData.AMOUNT){
            return true
          }else{
            this.btnCreditName = "เครดิตไม่พอชำระ"
            return false
          }
        }else{
          return false
        }
      },
      canCancelReserve:function(){
        if(this.listData.CUSTIDDS){
          if(this.reserveNo && this.reserveDate){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },
      amountBaht:function(){
        return this.amount ? this.amount.toLocaleString(undefined, {maximumFractionDigits: 2}) : '0'
      }
    }
};
</script>

<style scoped lang="scss">
.checkout-detail-title {
  padding: 10px 15px;
  font-size: 1.2em;
  font-family: "kittithadabold75regular";
  margin-bottom: 0;
}
.input-paylater{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
  background-color: lightgray !important
}

label:disabled,
label[disabled]{
  cursor: not-allowed;
  background-color: lightgray !important
}
</style>
